import { common } from "./common.service"

export const nfcService = {
    createNew,
    get,
    put,
    delete: remove
}

function createNew() {
    const url = `nfc`;
    return common.fetchAPI({ url, method: "GET", token: true });
}

/**
 * Pass an NFC id to the server to get the album or list of albums
 * to associate one of them to the NFC
 * @param {*} data 
 * @returns 
 */
async function get(id) {
    let url = `nfc/${id}`;
    const userIsLogged = !!localStorage.getItem('token');
    if (userIsLogged) {
        return common.fetchAPI({ url, method: "GET", token: true });
    } else {
        return common.fetchAPI({ url, method: "GET" });
    }
}

/**
 * Associate an album to an NFC
 * @param {*} id 
 * @param {*} data 
 * @returns 
 */
async function put(id, idAlbum) {
    let url = `nfc/${id}?album_id=${idAlbum}`;
    return common.fetchAPI({ url, method: "PUT", token: true });
}

/**
 * Associate an album to an NFC
 * @param {*} id 
 * @param {*} data 
 * @returns 
 */
async function remove(id, idAlbum) {
    let url = `nfc/${id}?album_id=${idAlbum}`;
    return common.fetchAPI({ url, method: "DELETE", token: true });
}
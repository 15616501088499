const KB = 1000;
const MB = 1000000;
const GB = 1000000000;

export const formatMemory = (memory, maxMemory=-1, withSimbol = true) => {
    const _maxMemory = maxMemory === -1 ? memory : maxMemory;
    if (_maxMemory < KB) {
        if (withSimbol) return `${memory} b`;
        return memory;
    }
    if (_maxMemory < MB) {
        if (withSimbol) return `${(memory / KB).toFixed(2)} Kb`;
        return (memory / KB).toFixed(2);
    }
    if (_maxMemory < GB) {
        if (withSimbol) return `${(memory / MB).toFixed(2)} Mb`;
        return (memory / MB).toFixed(2);
    }
    if (withSimbol) return `${(memory / GB).toFixed(2)} Gb`;
    return (memory / GB).toFixed(2);
}
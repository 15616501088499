import { common } from "./common.service";

export const collaboratorService = {
    add,
    update,
    delete: remove,
    get,
};

async function add(albumId, email, permissions = 'read') {
    let url = `collaborator`;
    const data = { 
        album_id: albumId,
        email,
        permissions,
     };
    return common.fetchAPI({
        url,
        method: "POST",
        token: true,
        data,
    });
}

async function update(albumId, collaboratorEmail, permissions = 'read') {
    let url = `collaborator`;
    const data = { 
        album_id: albumId,
        email: collaboratorEmail,
        permissions,
     };
    return common.fetchAPI({
        url,
        method: "PUT",
        token: true,
        data,
    });
}

async function remove(albumId, collaboratorEmail) {
    let url = `collaborator`;
    const data = { 
        album_id: albumId,
        email: collaboratorEmail
    };
    return common.fetchAPI({
        url,
        method: "DELETE",
        token: true,
        data
    });
}

async function get(albumId) {
    let url = `collaborator?album_id=${albumId}`;
    return common.fetchAPI({
        url,
        method: "GET",
        token: true,
    });
}
import React, { useEffect } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ModalFilters, Permission } from './modal-filters';

export const SortAlbum = ({ setAlbums, albums, originalAlbums }) => {
    const [sortValue, setSortValue] = React.useState('1');
    const [orderSortValue, setOrderSortValue] = React.useState('1');
    const [filters, setFilters] = React.useState({
        name: '',
        dateFrom: '',
        dateTo: '',
        permission: Permission.ALL,
    });

    const genericSort = (sortValue, orderSortValue) => {
        switch (sortValue) {
            case '1':
                return orderSortValue === '1' ? (a, b) => new Date(b.date_creation) - new Date(a.date_creation) : (a, b) => new Date(a.date_creation) - new Date(b.date_creation);
            case '2':
                return orderSortValue === '1' ? (a, b) => a.name.localeCompare(b.name) : (a, b) => b.name.localeCompare(a.name);
            case '3':
                return orderSortValue === '1' ? (a, b) => b.countFiles - a.countFiles : (a, b) => a.countFiles - b.countFiles;
            case '4':
                return orderSortValue === '1' ? (a, b) => b.memoryUsed - a.memoryUsed : (a, b) => a.memoryUsed - b.memoryUsed;
            default:
                return () => { };
        }
    };

    useEffect(() => {
        /**
         * name,
            dateFrom,
            dateTo,
            permission,
         */
        if (!originalAlbums) {
            return
        }

        const filteredAlbums = originalAlbums.filter((album) => {
            if (filters.name && !album.name.toLowerCase().includes(filters.name.toLowerCase())) {
                return false;
            }
            if (filters.dateFrom && new Date(album.date_creation) < new Date(filters.dateFrom)) {
                return false;
            }
            if (filters.dateTo && new Date(album.date_creation) > new Date(filters.dateTo)) {
                return false;
            }
            // permission 
            if (filters.permission === Permission.ALL) {
                // show all
                return true;
            }
            if (filters.permission === Permission.OWNED && !album.permission) {
                // show owned
                return true;
            }
            if (album.permission &&
                (album.permission === filters.permission ||
                    (album.permission === Permission.WRITE && filters.permission === Permission.READ))) {
                // show only read or read and write
                return true;
            }
            return false;
        });
        setAlbums(filteredAlbums);
    }, [filters, originalAlbums, setAlbums]);

    const handleSortChange = (event) => {
        const sortValue = event.target.value;
        setSortValue(sortValue);
        const sortedAlbums = [...albums].sort(genericSort(sortValue, orderSortValue));
        setAlbums(sortedAlbums);
    };

    const handleChangeOrderSortValue = () => {
        const nextOrderValue = orderSortValue === '1' ? '2' : '1';
        setOrderSortValue(nextOrderValue);
        const sortedAlbums = [...albums].sort(genericSort(sortValue, nextOrderValue));
        setAlbums(sortedAlbums);
    };

    const isFiltersActive = filters.name || filters.dateFrom || filters.dateTo || filters.permission !== Permission.ALL;

    return (
        <div className="d-flex justify-content-end">
            <select className="form-select mb-4" aria-label="Default select example" onChange={handleSortChange}>
                <option value="1">Data creazione</option>
                <option value="2">Nome</option>
                <option value="3">Numero Elementi</option>
                <option value="4">Memoria Occupata</option>
            </select>
            <button className="btn btn-secondary h-100 ms-2 " type="button" onClick={handleChangeOrderSortValue}>
                {orderSortValue === '1' ? <FontAwesomeIcon icon="fa-regular fa-sort-down" /> : <FontAwesomeIcon icon="fa-regular fa-sort-up" />}
            </button>
            <button className={`btn h-100 ms-2 ps-2 pe-2 ${isFiltersActive ? 'btn-primary' : 'btn-secondary'}`}
                type="button" data-bs-toggle="modal" data-bs-target="#filterModal">
                <FontAwesomeIcon icon="fa-regular fa-filter" />
            </button>
            <ModalFilters filters={filters} setFilters={setFilters} />
        </div>
    );
};
import React from 'react';
import { Button } from '../../ui';
import { initializeGoogleAnalytics } from '../../libs';
import Logo from '../../ui/assets/logo-text.png';


export function GAPermission() {
    const [accepted, setAccepted] = React.useState(true);
    const gaAutorizedLS = localStorage.getItem('gaAutorized');
    const [gaAutorized, setGaAutorized] = React.useState(gaAutorizedLS);

    if (gaAutorized === 'true' || gaAutorized === 'false') { 
        return null;
    }

    const handleAccept = () => {
        setAccepted(prev => !prev);
    }

    const handleContine = () => {
        const saveValue = accepted ? 'true' : 'false';
        localStorage.setItem('gaAutorized', saveValue);
        initializeGoogleAnalytics();
        setGaAutorized(saveValue);
    }

    const goToPrivacy = () => {
        const privacyUrl = "https://www.kangury.it/privacy-policy";
        if (window.ReactNativeWebView) {
            const message = {
                type: 'openUrlInBrowser',
                url: privacyUrl
            }
            window.ReactNativeWebView.postMessage(JSON.stringify(message));
        } else {
            window.open(privacyUrl, '_blank');
        }
    }
    // if privacy-policy in url not show GAPermission
    if (window.location.pathname.includes('privacy-policy')) {
        return null;
    }

    return (
        <div className={`vw-100 vh-100 d-flex flex-column align-items-center position-fixed z-1000 start-0 top-0 bg-white ps-4 pe-4 pb-5 justify-content-center`}>
            <div className="w-100 text-center mb-5 row justify-content-center">
                <img src={Logo} className="img-fluid pe-none col-8 col-sm-6 col-md-3" alt="Logo" unselectable="on" />
            </div>
            <div className='footer w-100'>
                <div className='fw-bold fs-5 mb-2'>
                    Per una migliore esperienza
                </div>
                <div className="form-check d-flex align-items-center mb-4">
                    <input className="form-check-input p-2 me-4" type="checkbox" id="flexCheckChecked" onChange={handleAccept} checked={accepted} />
                    <label className="form-check-label" htmlFor="flexCheckChecked">
                        Accetto l'uso di cookie di terze parti, analitici e per la profilazione.
                    </label>
                </div>
                <div className='text-muted mb-3 text-center'>
                    <small>
                    Maggiori informazioni nella sezione <span className='text-primary ms-1' data-bs-toggle="modal" data-bs-target="#privacyModal" onClick={goToPrivacy}>
                            privacy
                        </span>
                    </small>
                </div>
                <Button onClick={handleContine}>
                    Prosegui
                </Button>
            </div>
        </div >
    );
}

import React from 'react';
import { Page, BottomButton, SelectionFormatPriceItem, SelectionRadioPriceItem } from '../../components';
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getAllUrlParams, findMinPrice, getPropertyValueIds, getTemplatesByProperties, createCartItem } from '../../libs';
import { cartActions, productDataActions } from '../../store/actions';

export function Selection() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { subCategoryDict, general } = useSelector(state => state.mainData);
    const { categoryId, subCategoryId, selections } = getAllUrlParams();
    const prevSelectionsSelected = React.useMemo(() => selections?.split(';') || [], [selections]);

    // Variable to store the valid selected selections based on the previous selected selections
    // We use useRef and not useState because we aren't interested in re-rendering the component when this variable changes
    const validSelectionValues = React.useRef("");

    // index of the selection that is currently being selected from the list from 0 to N - 1
    const [indexSelectionValue, setIndexSelectionValue] = React.useState(0);

    React.useEffect(() => {
        dispatch(productDataActions.clean());
    }, [dispatch]);

    React.useEffect(() => {
        // When user go back in the navigation, here we reset the index of the selection selected to 0
        setIndexSelectionValue(0);
    }, [prevSelectionsSelected])

    const validProducts = React.useMemo(() => {
        //list of valid products based on the previous selected selections
        const products = [];
        let selectionValues = new Set();
        if (subCategoryDict) {
            const subCategory = subCategoryDict[subCategoryId];
            const allProducts = subCategory.subProductDict;
            Object.values(allProducts).forEach(product => {
                const selections = product.selectionValues.split(';');
                if (prevSelectionsSelected.every(o => selections.includes(o))) {
                    products.push(product);
                    selections.forEach(o => selectionValues.add(o));
                }
            });
        }
        validSelectionValues.current = selectionValues;
        return products;
    }, [subCategoryDict, subCategoryId, prevSelectionsSelected]);

    const currentSelection = React.useMemo(() => {
        // Current selection user see with values filtered based on the previous values selected
        const idCurrentSelection = subCategoryDict && subCategoryDict[subCategoryId].selectionIDs[prevSelectionsSelected.length];
        let currentSelection = idCurrentSelection && { ...subCategoryDict[subCategoryId].selectionDict[idCurrentSelection] };
        if (currentSelection?.values)
            currentSelection.values = currentSelection.values.filter((v) => validSelectionValues.current.has(v.id.toString()));
        return currentSelection;
    }, [subCategoryDict, subCategoryId, prevSelectionsSelected, validSelectionValues]);

    const bottomPrice = React.useMemo(() => {
        // Calculate the price displayed on the bottom of the page, near the button
        return findMinPrice({products: validProducts, selectionValueId: currentSelection?.values[indexSelectionValue]?.id}).replace(".", ",");
    }, [validProducts, currentSelection?.values, indexSelectionValue]);

    const handleButtonClick = () => {
        /**
         * When user click on the button, we add the current selection value selected to the list of previous selections selected
         * and we navigate to the next selection page
         * If there is no more selection to select, we navigate to:
         * 1) properties page if the product has properties
         * 2) template page if the product has no properties but more than one template
         * 3) product page if the product has no properties and only one template
        */
        const commonUrlParams = `categoryId=${categoryId}&subCategoryId=${subCategoryId}&`;
        const selectionValuesChoosen = currentSelection.values[indexSelectionValue].id;
        const selectionsInUrl = prevSelectionsSelected.length === 0
            ? selectionValuesChoosen.toString()
            : prevSelectionsSelected.join(';') + ';' + selectionValuesChoosen;
        setIndexSelectionValue(0);

        if (prevSelectionsSelected.length === subCategoryDict[subCategoryId].selectionIDs.length - 1) {
            const selectionsInUrlOrdered = selectionsInUrl.split(';').sort().join(';');
            const productSelected = validProducts.find(p => p.selectionValues === selectionsInUrlOrdered);
            if (productSelected?.properties) {
                navigate(`/property?${commonUrlParams}productId=${productSelected.id}`);
                return;
            }
            const {ids: propEnableinEditorId, values: propEnableinEditor} = getPropertyValueIds(productSelected.properties);
            const templates = getTemplatesByProperties(propEnableinEditor.split(";"), productSelected.templates);
            const hasMoreTemplates = templates.length > 1;
            if (hasMoreTemplates) {
                if (propEnableinEditor !== "") {
                    navigate(`/template?${commonUrlParams}productId=${productSelected.id}&properties=${propEnableinEditor}&propertiesId=${propEnableinEditorId}`);
                    return
                }
                navigate(`/template?${commonUrlParams}productId=${productSelected.id}`);
                return;
            }
            if (propEnableinEditor !== "") {
                navigate(`/warn-exit-product?${commonUrlParams}productId=${productSelected.id}&properties=${propEnableinEditor}&propertiesId=${propEnableinEditorId}`);
                return
            }
            if (productSelected.processingType === 5 || productSelected.processingType === 100) {
                dispatch(cartActions.add(createCartItem(productSelected, general)));
                navigate(`/cart`);
                return;
            }
            navigate(`/warn-exit-product?${commonUrlParams}productId=${productSelected.id}`);
            return

        }
        navigate(`/selection?${commonUrlParams}selections=${selectionsInUrl}`);
    }

    const addToCart = React.useMemo(() => {
        if (currentSelection && currentSelection.values[indexSelectionValue]) {
            const selectionValuesChoosen = currentSelection.values[indexSelectionValue].id;
            const selectionsInUrl = prevSelectionsSelected.length === 0
                ? selectionValuesChoosen.toString()
                : prevSelectionsSelected.join(';') + ';' + selectionValuesChoosen;
            const selectionsInUrlOrdered = selectionsInUrl.split(';').sort().join(';');
            const productSelected = validProducts.find(p => p.selectionValues === selectionsInUrlOrdered);
            return productSelected?.processingType === 5 || productSelected?.processingType === 100;
        }
        return false;
    }, [currentSelection, indexSelectionValue, prevSelectionsSelected, validProducts]);

    const renderCorrectSelection = React.useCallback(() => {
        // Render the correct selection based on the type of the selection
        if (subCategoryDict && subCategoryDict[subCategoryId]) {
            switch (currentSelection?.type) {
                case 1: // Example: size in "Classic Print"
                    return <SelectionFormatPriceItem currentSelection={currentSelection} indexSelectionValue={indexSelectionValue} setIndexSelectionValue={setIndexSelectionValue} products={validProducts} validSelectionValues={validSelectionValues.current} />
                default:
                    return <SelectionRadioPriceItem currentSelection={currentSelection} indexSelectionValue={indexSelectionValue} setIndexSelectionValue={setIndexSelectionValue} products={validProducts} type={currentSelection?.type} validSelectionValues={validSelectionValues.current} />
            }
        }
        return <></>;
    }, [currentSelection, indexSelectionValue, subCategoryDict, subCategoryId, validProducts]);

    return (
        <Page
            customBottomSection={
                <BottomButton content={addToCart ? "Aggiungi al carrello" : "Prosegui"} onClick={handleButtonClick} contentUp={`Prezzo: da ${bottomPrice}`} />}
            title={currentSelection?.name}>
            {renderCorrectSelection()}
        </Page>
    );
}
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useSelector } from 'react-redux';
import { estimateCategoryDeliveryDate } from '../../libs';


export function DeliveryDateCategory({ subCategoryId }) {
    const { subCategoryDict, productionTimeData, couriers } = useSelector((state) => state.mainData);
    const subCategory = subCategoryDict[subCategoryId];

    const dateFormatted = React.useMemo(() => {
        const product = subCategory?.subProductDict[Object.keys(subCategory.subProductDict)[0]];
        if (subCategory && productionTimeData && couriers && product.processingType !== 100) {
            const date = estimateCategoryDeliveryDate(subCategory, productionTimeData, couriers);
            // format date is Tue Dec 05 2023 15:31:47 GMT+0100 (Central European Standard Time)
            // cast to format 'Martedì 5 Dicembre'
            if (date)
                return date.toLocaleDateString('it-IT', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });
        }
        return null;
    }, [subCategory, productionTimeData, couriers]);

    return (
        dateFormatted && <div className='d-flex align-items-center'>
            <FontAwesomeIcon icon="fa-regular fa-truck-fast" size='2x' className='me-3' />
            <div>
                <div className=''>Ordina ora, consegna stimata:</div>
                <div className='fw-bold'>{dateFormatted}</div>
            </div>
        </div>
    );
}
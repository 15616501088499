import { common } from "./common.service"

export const versionService = {
    get
}

async function get() {
    let url = `version`;
    return common.fetchAPI({
        url,
        method: "GET"
    });
}
import React from 'react'
import { Button } from '..'

export function CardGift({ handleAccept, handleReject, title, sender, creationDate, message }) {

    return (
        <div className="card p-3 shadow">
            <div className="row g-0 fw-bold">
                {title}
            </div>
            <div className="card-body p-1">
                <div className='d-flex'>
                    <p className="m-0 fw-bold me-2">Da parte di </p>
                    <p className="m-0 mb-1">{sender}</p>
                </div>
                <div className='d-flex'>
                    <p className="m-0 fw-bold me-2">
                        Inviato il
                    </p>
                    <p className="m-0">
                        {creationDate}
                    </p>
                </div>
                {message && <div>
                    <p className="m-0 fw-bold">Messaggio</p>
                    <p className="m-0">
                        {message}
                    </p>
                </div>}
                <div className="row g-0 mt-2 justify-content-between">
                    <div className="col-5">
                        <Button otherClass={"p-0 btn-danger"} onClick={handleReject}>
                            Rifiuta
                        </Button>
                    </div>
                    <div className="col-5">
                        <Button otherClass={"p-0"} onClick={handleAccept}>
                            Accetta
                        </Button>
                    </div>

                </div>
            </div>
        </div>
    );
}
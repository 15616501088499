import React, { useEffect } from "react";
import { Page } from '../../components/page';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Accordion, Button, AlbumList as AlbumListUI, Modal } from "../../ui";
import { useNavigate } from 'react-router-dom';
import { getAllUrlParams } from "../../libs";
import { albumService, nfcService } from "../../store/services";
import ExampleGif from '../landing-page/lp_gif.gif';
import { GiftNotification } from "../../components";


export function SmartConnect() {
    const { id } = getAllUrlParams();
    const [loading, setLoading] = React.useState(id ? true : false);
    const navigate = useNavigate();
    const token = localStorage.getItem('token');
    const [errorCode, setErrorCode] = React.useState(null);

    const [albums, setAlbums] = React.useState(null);
    const [albumAssociating, setAlbumAssociating] = React.useState(null);

    useEffect(() => {
        if (id) {
            nfcService.get(id).then((data) => {
                if (data.status) {
                    return data.json();
                }
                if (data.albumId) {
                    setErrorCode(null)
                    navigate(`/album-list`);
                    navigate(`/album?id=${data.albumId}&fromNFC=true`);
                } else {
                    if (data.length === 0) {
                        setErrorCode(5);
                        return;
                    } else {
                        setAlbums(data);
                    }
                }
                return data.json();
            }).then((data) => {
                if (data?.errorCode) {
                    setErrorCode(data.errorCode);
                }
                setLoading(false);
            }).catch((err) => {
                setErrorCode(-1);
                setLoading(false);
            });
        }
    }, [id, navigate, token]);

    const _associateAlbum = (idAlbum) => {
        const album = albums.find((album) => album.id === idAlbum);
        setAlbumAssociating(album);
    }
    const handleChoosen = (idAlbum) => {
        setLoading(true);
        // Associate the album to the NFC
        nfcService.put(id, idAlbum).then((data) => {
            // if no status no error
            if (!data.status) {
                navigate(`/album-list`);
                navigate(`/album?id=${idAlbum}`);
            }
        }).catch((err) => {
            setErrorCode(-1);
            setLoading(false);
        });
    };

    if (albums) {
        return <Page loading={loading}>
            <div className="p-3 h-100">
                <h3 className="text-center mb-3">Scegli l'album da associare alla foto </h3>
                <AlbumListUI albums={albums} handleAction={_associateAlbum} associate={true} />
            </div>
            <Modal
                title={"Conferma associazione"}
                text={<p>Sei sicuro di voler associare l'album <span className="fw-bold">{albumAssociating?.name}</span> alla smartphoto? L'operazione è irreversibile.</p>}
                primaryText="Conferma"
                secondaryText="Annulla"
                handleConfirm={() => handleChoosen(albumAssociating.id)}
                id="confirmAssociate"
                primaryColor="btn-primary"
            />
        </Page>
    }

    if (!token && id) {
        return <Page>
            <div className="p-3 d-flex justify-content-center align-items-center flex-column h-100">
                <h3 className="text-center text-danger fw-bold mb-3">Esegui il login per visualizzare l'album</h3>
                <Button onClick={() => navigate('/login')}>Val al Login</Button>
            </div>
        </Page>
    }

    return (
        <Page loading={loading}>
            <div className="p-3">
                <GiftNotification />
            </div>
            <div className="p-3 d-flex align-items-center flex-column h-100 pb-5 pt-0">
                <div className='w-100 d-flex justify-content-center'>
                    <div className='col-12 col-sm-6'>
                        <div className='w-100 text-center fw-bold fs-1'>
                            Molto più di una foto!
                        </div>
                        <div className='w-100 text-center fs-5 fw-bold mb-2 lh-1'>
                            ..un portale verso i tuoi ricordi digitali
                        </div>

                        <div className='w-100 d-flex justify-space-between align-items-center'>
                            <div className="w-50 ratio ratio-9x16">
                                <img src={ExampleGif} alt="example" className="img-fluid" />
                            </div>
                            <div className='w-50 ps-1'>
                                <div className='text-center fs-6 lh-1 mb-3'>
                                    Ogni foto include un album cloud da 500MB personale, sicuro e per sempre, pronto ad accogliere immagini e video
                                </div>
                                <div className='text-center fs-6 mb-2 lh-1'>
                                    Potrai accedervi facilmente tramite questa app o semplicemente avvicinando il tuo smartphone alla foto che riceverai a casa
                                </div>
                            </div>
                        </div>
                        <div className='w-100 text-center'>
                            {/** Open link in new tab */}
                            <a href='/landing-page' className='text-tertiary'>
                                SCOPRI DI PIÙ
                            </a>
                        </div>
                        <div className='w-100 text-center mt-4'>
                            {/** Open link in new tab */}
                            <a href='/landing-page' className='text-primary fw-bold'>
                                ACQUISTA LA TUA PRIMA FOTO KANGURIZZATA!
                            </a>
                        </div>
                    </div>
                </div>
                <div className="w-100 mt-4 text-center">
                    {errorCode && <p className="text-danger text-center fw-bold">Errore: {traslateErrorCode(errorCode)}</p>}
                    <h2 className="mb-2">
                        Dubbi?
                    </h2>
                    <p className="text-center mb-2 fw-bold">
                        Abilita l'NFC e avvicina il dispositivo all'angolo in basso a destra della tua smartphoto acquistata su Kangury
                    </p>
                    <div className="w-100">
                        <Accordion items={helps} />
                    </div>
                </div>
                <div className="w-100 text-center p-2">

                </div>
            </div>
        </Page>
    );
}

const traslateErrorCode = (errorCode) => {
    switch (errorCode) {
        case 1:
            return "Dispositivo NFC non trovato nel database, riprovare o contattare l'assistenza";
        case 2:
            return "NFC già aggiunto ma album associato non trovato, riprovare o contattare l'assistenza";
        case 3:
            return "Accesso negato, NFC associato ad un altra persona";
        case 4:
            return "4, contattare l'assistenza";
        case 5:
            return "Nessun album valido trovato, contattare l'assistenza";
        default:
            return "Server non raggiungibile, controllare la connessione o riprovare più tardi";
    }
}

const helps = [
    {
        id: 1,
        question: "Come posso abilitare l'NFC?",
        answer: "Per abilitare l'NFC sul tuo dispositivo, vai nelle impostazioni del tuo smartphone e cerca la voce NFC. Una volta trovata, attivala. Se non la trovi cerca su google inserendo il modello del tuo smartphone."
    },
    {
        id: 2,
        question: "Come posso leggere la smartphoto?",
        answer: "Per leggere la smartphoto, avvicina il dispositivo alla smartphoto acquistata su Kangury nell'angolo in basso a destra."
    },
    {
        id: 3,
        question: "Cosa posso fare se il dispositivo non legge la smartphoto?",
        answer: "Se il dispositivo non legge la smartphoto, assicurati che l'NFC sia attivo. Se il problema persiste, contatta il nostro servizio clienti."
    }
]
import React, { useState } from 'react';
import { Page } from '../../../components';
import { adminService } from '../../../store/services';
import { Button } from '../../../ui';

export function AdminUnlockOrder() {
    const [orderId, setOrderId] = useState('');
    const [error, setError] = useState('');

    const unlockOrder = () => {
        adminService.unlockOrder(orderId).then((data) => {
            if (data.status === 200) {
                setError('Ordine sbloccato con successo');
            } else {
                setError('Errore nello sblocco dell\'ordine');
            }
        });
    };

    return (
        <Page visibleBottomNavigation={false} title={"Pannello Amministratore"}>
            <div className='p-2'>
                <div className='mb-3'>
                    <label>Id Ordine</label>
                    <input
                        type="email"
                        className="form-control"
                        value={orderId}
                        onChange={(e) => setOrderId(e.target.value)}
                    />
                </div>
                <Button onClick={unlockOrder} otherClass={"btn-primary mt-3"}>
                    Sblocca l'ordine
                </Button>
                {error && <div className="alert alert-danger mt-3">{error}</div>}
            </div>
        </Page>
    );
}
import { common } from "./common.service"

export const giftService = {
    get,
    post,
    put,
    remove
}

/**
 * Get all gift where user is the sender or the receiver
 */
async function get() {
    let url = `gift`;
    return common.fetchAPI({ url, method: "GET", token: true });
}

/**
 * Service to send an album as a gift to another user
 * @param {*} albumId 
 * @param {*} email 
 * @returns 
 */
async function post(albumId, recipientEmail, message) {
    let url = `gift`;
    return common.fetchAPI({
        url, method: "POST", token: true,
        data: { album_id: albumId, recipient_email: recipientEmail, message }
    });
}

/**
 * Service to accept a gift and add the album to the user's library
 * @param {*} albumId 
 * @returns 
 */
async function put(albumId) {
    let url = `gift/${albumId}`;
    return common.fetchAPI({ url, method: "PUT", token: true });
}

/**
 * Service to remove a gift, called to cancel or reject a gift
 * @param {*} albumId 
 * @returns 
 */
async function remove(albumId) {
    let url = `gift/${albumId}`;
    return common.fetchAPI({ url, method: "DELETE", token: true });
}
import { traslateCodeToStatus } from '../../libs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { LoadingSpinner } from '../loading-spinner';

export function CardOrders({ orderNumber, status, date, price, payNow, setRemoveIndex, showDetails, orderDetails }) {
    const statusLabel = traslateCodeToStatus(status);
    const [isAccordionOpen, setIsAccordionOpen] = useState(false);

    const handleAccordionToggle = () => {
        setIsAccordionOpen(!isAccordionOpen);
        if (!isAccordionOpen) {
            showDetails();
        }
    };

    return (
        <div className="card p-2 shadow rounded-3 mb-4">
            <div className="fw-bold">
                Ordine {orderNumber}
            </div>
            <div className='text-primary fw-bold'>
                {statusLabel}
            </div>
            <div>
                Data: {new Date(date).toLocaleDateString('it-IT', { day: '2-digit', month: '2-digit', year: 'numeric' })}
            </div>
            <div>
                Totale:<span className='fw-bold'> {price.toFixed(2).replace(".", ",")} €</span>
            </div>
            {statusLabel === "In Attesa di Pagamento" &&
                <div>
                    <div className='d-flex gap-2 h-100 mt-1'>
                        <button className="btn btn-primary rounded-5 fs-6 fw-bold" onClick={payNow}>
                            Paga Ora
                        </button>
                        <button className="btn btn-secondary rounded-5 fs-6  fw-bold" data-bs-toggle="modal" data-bs-target="#orderModal" onClick={setRemoveIndex}>
                            Cancella
                        </button>
                    </div>
                </div>}
            <div className="accordion mt-3">
                <div className="accordion-item">
                    <h2 className="accordion-header border-bottom" id={`heading${orderNumber}`}>
                        <button className="accordion-button p-1 collapsed" type="button" onClick={handleAccordionToggle} aria-expanded={isAccordionOpen} aria-controls={`collapse${orderNumber}`}>
                            Dettagli Ordine
                        </button>
                    </h2>
                    <div id={`collapse${orderNumber}`} className={`accordion-collapse collapse ${isAccordionOpen ? 'show' : ''}`} aria-labelledby={`heading${orderNumber}`}>
                        <div className="accordion-body p-1 ps-0 pe-0">
                            {orderDetails.length > 0 ? <ul className='m-0'>
                                {
                                    orderDetails.map((item, index) => (
                                        <li key={index}>
                                            {item.quantity} x {item.name}
                                        </li>
                                    ))
                                }
                            </ul> :
                                <div className='text-center'>
                                    <LoadingSpinner />
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
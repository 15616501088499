import React from "react";
import { Page } from '../../components/page';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Carousel } from "../../ui";
import { useNavigate } from 'react-router-dom';
import ExampleGif from '../landing-page/lp_gif.gif';


export function Home() {
    const navigate = useNavigate();

    const renderSlide = (content, showArrow = true) => {
        return <div className="h-100 w-100 d-flex align-items-center flex-column text-center pb-5 justify-content-center position-relative slider-container">
            {content}
            {showArrow && <div className="position-absolute bottom-0 mb-4">
                <FontAwesomeIcon icon="fa-regular fa-arrow-right" size="3x" className="text-primary" />
            </div>}
        </div>
    }

    const goToDubts = () => {
        navigate('/how-it-works?scrollTo=dubts');
    }

    const goToHowToWork = () => {
        navigate('/how-it-works');
    }

    const goToShop = () => {
        navigate('/shop');
    }

    return (
        <Page logoOnTop={true}>
            <div className="w-100 h-100">
                <Carousel items={[
                    renderSlide(
                        <>
                            <h1 className="mb-5 text-white">
                                Quanto erano
                                belle quelle foto di
                                quella vacanza
                                incredibile...
                            </h1>
                            <h1 className="text-primary">
                                Se solo non mi
                                si fosse rotto
                                il telefono! 😓😩
                            </h1>
                        </>
                    ),
                    renderSlide(
                        <>
                            <h1 className="mb-5 text-white">
                                Quella festa è stata
                                davvero unica!
                            </h1>
                            <h1 className="text-primary">
                                Ma non mi andava
                                di pagare il cloud
                                ogni mese e le ho
                                messe sul PC?
                                sulla chiavetta?
                                Bho dopo vedo! 🤦🏻‍♂️
                            </h1>
                        </>
                    ),
                    renderSlide(
                        <>
                            <h1 className="mb-5 text-primary">
                                Noi abbiamo
                                la soluzione
                            </h1>
                            <h1 className="text-white">
                                Un Cloud in cui
                                salvare quello
                                che vuoi tu per
                                sempre
                            </h1>
                            <h1 className="text-primary">
                                senza costi
                                ricorrenti
                            </h1>
                        </>
                    ),
                    renderSlide(
                        <>
                            <h1 className="text-white">
                                Non preoccuparti di
                                cercare fra le cartelle:
                            </h1>
                            <h1 className="text-primary mb-5">
                                sfiora la foto e comparirà
                                sul tuo telefono!
                            </h1>
                            <div className="mh-50 mw-50">
                                <img src={ExampleGif} alt="example" className="w-100 h-100" />
                            </div>
                        </>
                    ),
                    renderSlide(
                        <>
                            <h1 className="text-white mb-5">
                                Cosa aspetti?
                            </h1>
                            <h1 className="text-primary">
                                Acquista
                            </h1>
                            <h1 className="text-white">
                                subito la tua
                            </h1>
                            <h1 className="text-primary mb-5">
                                prima foto
                            </h1>
                            <div className="w-50">
                                <Button onClick={goToShop}>
                                    <h2 className="fw-bold">
                                        ACQUISTA
                                    </h2>
                                </Button>
                            </div>
                            <div className="position-absolute bottom-0 mb-4 fs-4">
                                <div className="mb-2 text-decoration-underline text-white cursor-pointer" onClick={goToDubts}>
                                    Dubbi?
                                </div>
                                <div className="mb-2 text-decoration-underline text-primary cursor-pointer" onClick={goToHowToWork}>
                                    Vuoi saperne di più?
                                </div>
                            </div>
                        </>, false
                    ),
                ]}
                />
            </div>
        </Page>
    );
}


import React, { useState } from 'react';
import { Page } from '../../../components';
import { adminService } from '../../../store/services';
import { Button } from '../../../ui';

export function AdminDiscounts() {
    const [code, setCode] = useState('');
    const [credits, setCredits] = useState(10);
    const [description, setDescription] = useState('');
    const [emailText, setEmailText] = useState('');
    const [emails, setEmails] = useState('');
    const [expiresDate, setExpiresDate] = useState(new Date(new Date().setMonth(new Date().getMonth() + 1)).toISOString().split('T')[0]);
    const [minItems, setMinItems] = useState(0);
    const [discountsCodeCreated, setDiscountsCodeCreated] = useState(false);
    const [multipleUse, setMultipleUse] = useState(false);
    const [singleUseForUser, setSingleUseForUser] = useState(false);
    const [visible, setVisible] = useState(false);

    const sendDiscountsCode = () => {
        const discountData = {
            code,
            emailText,
            credits: parseInt(credits),
            description,
            emails: emails.split('\n'),
            expiresDate,
            minimumItems: parseInt(minItems),
            multipleUse,
            singleUseForUser,
            visible,
        };

        // Call the service to send the discount code
        adminService.sendDiscounts(discountData)
            .then(response => {
                setDiscountsCodeCreated(true);
                setTimeout(() => {
                    setDiscountsCodeCreated(false);
                }, 5000);
            })
            .catch(error => {
                console.error('Error sending discount code:', error);
            });
    };

    return (
        <Page visibleBottomNavigation={false} title={"Pannello Amministratore"}>
            <div className='p-2'>
                <div className='mb-3'>
                    <label>Codice sconto personalizzato (se non inserito ne genera uno randomico, se inserito ed esiste si limita ad inviare email)</label>
                    <input
                        className="form-control"
                        value={code}
                        onChange={(e) => setCode(e.target.value)}
                    />
                </div>
                <div className='mb-3'>
                    <label>Crediti del Codice Sconto</label>
                    <input
                        type="number"
                        className="form-control"
                        value={credits}
                        onChange={(e) => setCredits(e.target.value)}
                    />
                </div>
                <div className='mb-3'>
                    <label>Descrizione del Codice Sconto</label>
                    <input
                        type="text"
                        className="form-control"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                    />
                </div>
                <div className='mb-3'>
                    <label>Testo dell'Email</label>
                    <textarea
                        className="form-control"
                        rows="5"
                        value={emailText}
                        onChange={(e) => setEmailText(e.target.value)}
                    />
                </div>
                <div className='mb-3'>
                    <label>Email (una per riga, se vuoto come campo crea un codice sconto per tutti)</label>
                    <textarea
                        className="form-control"
                        rows="5"
                        value={emails}
                        onChange={(e) => setEmails(e.target.value)}
                    />
                </div>
                <div className='mb-3'>
                    <label>Data di Scadenza del Codice Sconto</label>
                    <input
                        type="date"
                        className="form-control"
                        value={expiresDate}
                        onChange={(e) => setExpiresDate(e.target.value)}
                    />
                </div>
                <div className='mb-3'>
                    <label>Numero Minimo di Items da Acquistare</label>
                    <input
                        type="number"
                        className="form-control"
                        value={minItems}
                        onChange={(e) => setMinItems(e.target.value)}
                    />
                </div>
                <div className="d-flex justify-content-between align-items-center form-check form-switch p-1">
                    <div>Codice sconto multiuso</div>
                    <div>
                        <input className="form-check-input cursor-pointer" type="checkbox" id="flexSwitchCheckDefault" onClick={() => setMultipleUse(!multipleUse)} value={multipleUse} checked={multipleUse} />
                    </div>
                </div>
                <div className="d-flex justify-content-between align-items-center form-check form-switch p-1">
                    <div>
                        Codice sconto singolo per utente
                    </div>
                    <div>
                        <input className="form-check-input cursor-pointer" type="checkbox" id="flexSwitchCheckDefault" onClick={() => setSingleUseForUser(!singleUseForUser)} value={singleUseForUser} checked={singleUseForUser} />
                    </div>
                </div>
                <div className="d-flex justify-content-between align-items-center form-check form-switch p-1">
                    <div>
                        Codice sconto visibile
                    </div>
                    <div>
                        <input className="form-check-input cursor-pointer" type="checkbox" id="flexSwitchCheckDefault" onClick={() => setVisible(!visible)} value={visible} checked={visible} />
                    </div>
                </div>
                {
                    discountsCodeCreated &&
                    <div className='alert alert-success'>
                        {emails.split('\n').length > 1
                            ? 'Codici Sconto creati ed inviati con successo!'
                            : 'Codice Sconto creato con successo ed inviato!'}
                    </div>
                }
                <Button onClick={sendDiscountsCode} otherClass={"btn-primary mt-3"}>
                    Invia Codice Sconto
                </Button>
            </div>
        </Page>
    );
}
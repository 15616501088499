import React from "react";
import { CardSimple } from "../../ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const ShareNotification = ({ albumsList }) => {
    const collaboratorNotSeen = albumsList && albumsList.filter && albumsList.filter(album => album.seen === false).length > 0;

    if(!collaboratorNotSeen) return null;

    return (
        <CardSimple>
            <div className="d-flex justify-content-between align-items-center">
                <FontAwesomeIcon icon={`fa-regular fa-share-nodes`} />
                <div className="text-center pe-2 ps-2">
                    Qualcuno ha condiviso un nuovo album con te, guardalo!
                </div>
                <FontAwesomeIcon icon={`fa-regular fa-share-nodes`} />
            </div>
        </CardSimple>
    )
}
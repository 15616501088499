import React, { useEffect } from "react";
import { Button, CardSimple } from "../../ui";
import { useNavigate } from 'react-router-dom';
import { giftService } from "../../store/services";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const GiftNotification = ({giftPreload = null}) => {
    const [gifts, setGifts] = React.useState(giftPreload);
    const navigate = useNavigate();

    useEffect(() => {
        if (!gifts) {
            giftService.get().then((res) => {
                setGifts(res);
            });
        }
    }, [gifts]);

    // check if there is gifts where user is not the senderEmail
    const giftInPending = gifts && gifts.filter && gifts.filter(gift => gift.senderEmail !== localStorage.getItem('email')).length > 0;

    const goToGifts = () => {
        navigate('/gift-list');
    }

    if(!giftInPending) return null;

    return (
        <CardSimple>
            <div className="d-flex justify-content-between align-items-center">
                <FontAwesomeIcon icon={`fa-regular fa-gift`} />
                <div className="text-center">
                    Qualcuno ti ha regalato un album!
                </div>
                <FontAwesomeIcon icon={`fa-regular fa-gift`} />
            </div>
            <div className="w-50 mx-auto mt-2">
                <Button otherClass="btn-primary p-0" onClick={goToGifts}>
                    <span className="text-tertiary fs-6">
                        Vai ai Regali
                    </span>
                </Button>
            </div>
        </CardSimple>
    )
}
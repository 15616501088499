import React, { useEffect } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ModalFilters, UNDEFINED_FILTER } from './modal-filters';

export const SortMedia = ({ setMedia, media, originalMedia, data, emailUser }) => {
    const [sortValue, setSortValue] = React.useState('1');
    const [orderSortValue, setOrderSortValue] = React.useState('1');
    const [filters, setFilters] = React.useState({
        owner: UNDEFINED_FILTER,
        favorite: UNDEFINED_FILTER
    });

    const genericSort = (files, sortOption, orderValue) => {
        return files.sort((a, b) => {
            switch (sortOption) {
                case "1": // Default (copertina, preferiti e proprie foto)
                    if (a.id === data.coverId) return orderValue === '1' ? -1 : 1;
                    if (b.id === data.coverId) return orderValue === '1' ? 1 : -1;
                    if (a.favorite && !b.favorite) return orderValue === '1' ? -1 : 1;
                    if (!a.favorite && b.favorite) return orderValue === '1' ? 1 : -1;
                    if (a.owner === emailUser && b.owner !== emailUser && emailUser !== null) return orderValue === '1' ? -1 : 1;
                    if (a.owner !== emailUser && b.owner === emailUser && emailUser !== null) return orderValue === '1' ? 1 : -1;
                    if (a.id < b.id) return orderValue === '1' ? -1 : 1;
                    if (a.id > b.id) return orderValue === '1' ? 1 : -1;
                    return 0;

                case "2": // Preferiti
                    if (a.favorite && !b.favorite) return orderValue === '1' ? -1 : 1;
                    if (!a.favorite && b.favorite) return orderValue === '1' ? 1 : -1;
                    return 0;

                case "3": // Ordine di caricamento basato sul valore dell'id e orderValue
                    return orderValue === '1' ? a.id - b.id : b.id - a.id;
                case "4": // Dimensione file
                    return orderValue === '1' ? a.size - b.size : b.size - a.size;

                case "5": // Proprie foto
                    if (a.owner === emailUser && b.owner !== emailUser) return orderValue === '1' ? -1 : 1;
                    if (a.owner !== emailUser && b.owner === emailUser) return orderValue === '1' ? 1 : -1;
                    return 0;

                case "6": // Tipo file
                    return orderValue === '1' ? a.type.localeCompare(b.type) : b.type.localeCompare(a.type);

                default:
                    return 0;
            }
        });
    };

    useEffect(() => {
        /**
         * name,
            dateFrom,
            dateTo,
            permission,
         */
        if (!originalMedia) {
            return
        }

        let filteredMedia = originalMedia.filter((file) => {
            if (filters.owner === UNDEFINED_FILTER && filters.favorite === UNDEFINED_FILTER) {
                return true;
            }
            if (filters.owner && file.owner === filters.owner && filters.favorite === UNDEFINED_FILTER) {
                return true;
            }
            if (file.favorite.toString() === filters.favorite && filters.owner === UNDEFINED_FILTER) {
                return true;
            }
            if (file.favorite.toString() === filters.favorite && file.owner === filters.owner) {
                return true;
            }
            return false;
        });
        if (sortValue !== '1') {
            filteredMedia = genericSort(filteredMedia, sortValue, orderSortValue);
        }
        setMedia(filteredMedia);
    }, [filters, originalMedia]);

    const handleSortChange = (event) => {
        const sortValue = event.target.value;
        setSortValue(sortValue);
        const sortedMedia = genericSort(media, sortValue, orderSortValue);
        setMedia([...sortedMedia]);
    };

    const handleChangeOrderSortValue = () => {
        const nextOrderValue = orderSortValue === '1' ? '2' : '1';
        setOrderSortValue(nextOrderValue);
        const sortedMedia = genericSort(media, sortValue, nextOrderValue);
        setMedia([...sortedMedia]);
    };

    const filtersIsActivated = filters.owner !== UNDEFINED_FILTER || filters.favorite !== UNDEFINED_FILTER;

    return (
        <div className="d-flex justify-content-end">
            <select className="form-select mb-4" aria-label="Default select example" onChange={handleSortChange}>
                <option value="1">Default (copertina, preferiti e proprie foto)</option>
                <option value="2">Preferiti</option>
                <option value="3">Ordine di caricamento</option>
                <option value="4">Dimensione file</option>
                <option value="5">Proprie foto</option>
                <option value="6">Tipo file</option>
            </select>
            <button className="btn btn-secondary h-100 ms-2 " type="button" onClick={handleChangeOrderSortValue}>
                {orderSortValue === '1' ? <FontAwesomeIcon icon="fa-regular fa-sort-down" /> : <FontAwesomeIcon icon="fa-regular fa-sort-up" />}
            </button>
            <button className={`btn h-100 ms-2 ps-2 pe-2 ${filtersIsActivated ? 'btn-primary' : 'btn-secondary'}`}
                type="button" data-bs-toggle="modal" data-bs-target="#filterModal">
                <FontAwesomeIcon icon="fa-regular fa-filter" />
            </button>
            <ModalFilters setFilters={setFilters} originalMedia={originalMedia} />
        </div>
    );
};
import React from "react";
import { CardAlbum } from "..";
import { formatDate, formatMemory } from "../../libs";

export function AlbumList({ albums, handleAction, associate = false, gifts, isAdmin, handleDeleteAdmin }) {

    const _handleAction = (id) => {
        handleAction(id);
    }

    const _handleDeleteAdmin = (id) => {
        handleDeleteAdmin(id);
    }

    const email = localStorage.getItem('email');

    return (
        <div className="row">
            {albums && albums.map((album) => (
                <div className="col-12 col-md-6 col-lg-4 mb-4" key={album.id}>
                    <CardAlbum
                        handleAction={() => _handleAction(album.id)}
                        handleDeleteAdmin={isAdmin ? () => _handleDeleteAdmin(album.id) : undefined}
                        title={album.name === "-" ? "Album senza nome" : album.name}
                        files={album.countFiles}
                        memoryUsed={formatMemory(album.memoryUsed, album.memoryTotal, false)}
                        memoryTotal={formatMemory(album.memoryTotal, album.memoryTotal)}
                        cover={album.latestFileUrl}
                        coverType={album.latestFileType}
                        associate={associate}
                        creationDate={formatDate(album.date_creation)}
                        nfc={album.nfc}
                        sentToGift={gifts && gifts.length > 0 && gifts.filter((gift) => gift.albumId === album.id && gift.senderEmail === email).length > 0}
                        showShareNotification={album.permission && !album.seen}
                        showShare={album.permission}
                    />
                </div>
            ))}
        </div>
    );
}
import React, { useEffect, useMemo } from "react";
import { Page } from '../../components/page';
import { Button, ImgWithPlaceholder, PlaceholdersLoaderGif, ToastInfo } from "../../ui";
import { useNavigate } from "react-router-dom";
import { MediaCarousel, MediaSettings, UploadButtonAlbum } from "../../components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { albumService, favoriteService } from "../../store/services";
import { getAllUrlParams, isIos, formatMemory } from "../../libs";
import { Modals } from "./modals";
import { CollaboratorPermissions, AlbumPrivacyValues } from "../album-privacy";
import { SortMedia } from "./sort";

export function Album() {
    const { id, fromNFC, gallery } = getAllUrlParams();
    const navigate = useNavigate();
    const [data, setData] = React.useState(undefined);
    const [media, setMedia] = React.useState(undefined);
    const [originalMedia, setOriginalMedia] = React.useState(undefined);
    const [indexMediaClicked, setIndexMediaClicked] = React.useState(null);
    const [settingId, setSettingId] = React.useState(null);
    const [showToast, setShowToast] = React.useState(false);
    const [messageToast, setMessageToast] = React.useState('');
    const [showNotAccessError, setShowNotAccessError] = React.useState(false);
    const owner = data && data.owner;
    const emailUser = localStorage.getItem('email')
    const isOwner = owner && owner === emailUser;
    const isLogged = localStorage.getItem('token') ? true : false;
    const isWriteCollaborator = data && (data.permission === CollaboratorPermissions.WRITE || data.privacy === AlbumPrivacyValues.PUBLIC_WRITE);

    useEffect(() => {
        if (!data) {
            albumService.get(id).then((res) => {
                if (res.status === 403) {
                    setShowNotAccessError(true);
                    return;
                }
                setData(res);
            });
        }
    }, [data, id]);

    useEffect(() => {
        if (gallery) {
            const index = parseInt(gallery);
            if (index >= 0 && index < media?.length) {
                setIndexMediaClicked(index);
            } else {
                setIndexMediaClicked(null);
            }
        } else {
            setIndexMediaClicked(null);
            setSettingId(null);
        }
    }, [gallery, media?.length]);

    useEffect(() => {
        if (!media && data) {
            albumService.getFiles(id).then((res) => {
                if (res.status)
                    return;
                const files = res.files;
                const paramsString = res.params;
                const params = new URLSearchParams(paramsString);
                // add params to all files url and urlReduced
                if (process.env.REACT_APP_NODE_ENV === 'production') {
                    files.forEach((item) => {
                        item.url = `${item.url}?${params.toString()}`;
                        item.urlReduced = item.urlReduced ? `${item.urlReduced}?${params.toString()}` : item.url;
                    });
                }
                // Order file to have cover first position, after favorite, after owner and after the rest
                files.sort((a, b) => {
                    if (a.id === data.coverId) return -1;
                    if (b.id === data.coverId) return 1;
                    if (a.favorite && !b.favorite) return -1;
                    if (!a.favorite && b.favorite) return 1;
                    if (a.owner === emailUser && b.owner !== emailUser && emailUser !== null) return -1;
                    if (a.owner !== emailUser && b.owner === emailUser && emailUser !== null) return 1;
                    if (a.id < b.id) return -1;
                    if (a.id > b.id) return 1;
                    return 0;
                });
                setMedia(files);
                setOriginalMedia(files);
                if (fromNFC) {
                    const indexCover = files.findIndex((item) => item.id === data.coverId);
                    // remove fromNFC from url
                    navigate(`/album?id=${id}`);
                    navigate(`/album?id=${id}&gallery=${indexCover}`);
                }
            });
        }
    }, [data, emailUser, fromNFC, id, media, navigate]);

    const handlePrivacy = () => {
        navigate(`/album-privacy?id=${id}&privacy=${data.privacy}&title=${data.name}`);
    }

    const handleGift = () => {
        navigate(`/album-gift?id=${id}&title=${data.name}`);
    }

    const handleNewMedia = (newMedia) => {
        setOriginalMedia([...originalMedia, ...newMedia]);
        // Get new size of album
        albumService.get(id).then((res) => {
            setData(res);
        });
    }

    const handleClickMedia = (index) => {
        navigate(`/album?id=${id}&gallery=${index}`);
    }

    const handleAlbumLink = () => {
        // const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        const url = window.location.href;
        const text = `Guarda il mio album!: ${url}`;
        const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(text)}`;
        window.open(whatsappUrl, '_blank');
    };

    const handleDeleteFile = () => {
        const id = media[indexMediaClicked].id;
        albumService.deleteFile(id).then((res) => {
            if (res.status === 204) {
                const newMedia = media.filter((item) => item.id !== id);
                setOriginalMedia(newMedia);
                setSettingId(null);
                // update indexMediaClicked
                if (newMedia.length === 0) {
                    setIndexMediaClicked(null);
                } else {
                    setIndexMediaClicked(prev => prev - 1);
                }
            }
        });
    }

    const handleSetCover = () => {
        const idFile = media[indexMediaClicked].id;
        albumService.setAlbumCover(id, idFile).then((res) => {
            setMessageToast('Cover cambiata con successo');
            setShowToast(true);
            setTimeout(() => {
                setShowToast(false);
            }, 3000);
        });
    }

    const handleToggleFavorite = () => {
        const idFile = media[indexMediaClicked].id;
        const favorite = media[indexMediaClicked].favorite;
        favoriteService.toggle(idFile, favorite).then((res) => {
            const newMedia = media.map((item) => {
                if (item.id === idFile) {
                    item.favorite = !item.favorite;
                }
                return item;
            });
            setOriginalMedia(newMedia);
        });
    }

    const confirmChangeName = () => {
        const newName = { name: data.name };
        albumService.put(id, newName).then((res) => {
        });
    }

    const goToAdvancedSettings = () => {
        navigate(`/album-settings?id=${id}`);
    }

    const organizeMedia = useMemo(() => {
        if (!media || !media.forEach) return { col1: [], col2: [], col3: [] };
        const col1 = [], col2 = [], col3 = [];
        media.forEach((item, index) => {
            if (index % 3 === 0)
                col1.push(item);
            else if (index % 3 === 1)
                col2.push(item);
            else
                col3.push(item);
        });
        return { col1, col2, col3 };
    }, [media]);

    if (showNotAccessError) {
        return <Page title="Errore">
            <div className="container mt-2">
                Non hai accesso a questo album, se il link ti è stato condiviso da un amico digli di modificarne la privacy
            </div>
        </Page>
    }

    return (
        <Page
            title={
                <div className='text-decoration-underline cursor-pointer'
                    data-bs-toggle="modal" data-bs-target={isOwner ? "#changeName" : ""}>
                    {data && data?.name !== "-" ? data.name : "clicca per rinominare"}
                    {isOwner && <FontAwesomeIcon icon="fa-regular fa-pen" className="ms-2" />}
                </div>
            }
            scrollButtons={gallery === undefined}
            loading={data === undefined || media === undefined}
            hideContainer={indexMediaClicked !== null}
            customBottomSection={!isOwner
                ? <></>
                : data &&
                <div className="border-top p-2">
                    <div className="d-flex justify-content-between align-items-center">
                        <div>
                            {data.mediaCount === media?.length
                                ? <div>
                                    <span className="fw-bold me-1">Elementi:</span>
                                    {data.mediaCount}
                                </div>
                                : <div>
                                    <span className="fw-bold me-1">Elementi inclusi nel filtro:</span>
                                    {media?.length} / {data.mediaCount}
                                </div>}
                            <div>
                                <span className="fw-bold me-1">Associato ad una Smartphoto:</span>
                                {data.nfc ? 'Si' : 'No'}
                            </div>
                            <div>
                                <span className="fw-bold me-1">Memoria usata:</span>
                                {formatMemory(data.memoryUsed, data.memoryTotal, false)} / {formatMemory(data.memoryTotal, data.memoryTotal)}
                            </div>
                        </div>
                        {data.privacy !== 0 && <FontAwesomeIcon icon="fa-regular fa-share-nodes" className="text-dark bg-primary fs-3 border border-2 rounded-5 p-1 ps-2 pe-2" onClick={handleAlbumLink} />}
                    </div>
                    <div className={`d-flex justify-content-between mt-1 gap-1 ${isIos() ? 'pb-3' : 'p-0'}`}>
                        <Button otherClass={"p-1 btn-tertiary text-white"} onClick={goToAdvancedSettings}>
                            <FontAwesomeIcon icon="fa-regular fa-gear" className="me-2" />
                            Avanzate
                        </Button>
                        <Button otherClass={"p-1 btn-secondary"}
                            onClick={handlePrivacy}>
                            Condivisione
                        </Button>
                        <Button otherClass={"p-1"} onClick={handleGift}>
                            Regala
                        </Button>
                    </div>
                </div>
            }>

            {
                !media ? <div>Loading...</div> :
                    <React.Fragment>
                        <div className="container mt-1">
                            {isOwner && !isLogged && <div className="rounded bg-tertiary mb-2 p-1 text-center bg-opacity-75 border border-primary border-2 text-primary">
                                Crea anche tu il tuo album su Kangury
                                <div>
                                    <a href="/" className="text-primary ms-1">Inizia ora!</a>
                                </div>
                            </div>}
                            {originalMedia.length > 0 &&
                                <SortMedia media={media} setMedia={setMedia} originalMedia={originalMedia} data={data} emailUser={emailUser} />
                            }
                            <div className={`row g-1 mb-1 ${indexMediaClicked !== null ? 'd-none' : ''}`}>
                                {['col1', 'col2', 'col3'].map((col, colIndex) => (
                                    <div key={colIndex} className="col-4">
                                        {organizeMedia[col].map((item, index) => {
                                            const src = item.urlReduced || item.url;
                                            return (
                                                <ImgWithPlaceholder
                                                    src={src}
                                                    alt="image"
                                                    placeholder={PlaceholdersLoaderGif.loader_1200_1200}
                                                    className={`w-100 mb-1 cursor-pointer ${item.id === data.coverId ? 'border border-primary border-3' : ''}`}
                                                    onClick={() => handleClickMedia(index * 3 + colIndex)}
                                                    type={item.type}
                                                    id={item.id}
                                                    isVideo={item.type?.includes?.('video')}
                                                    favorite={item.favorite}
                                                />
                                            );
                                        })}
                                    </div>
                                ))}
                            </div>
                            {(isOwner || isWriteCollaborator) && <UploadButtonAlbum
                                active={true}
                                memoryAvailable={data.memoryTotal - data.memoryUsed}
                                handleNewMedia={handleNewMedia}
                                albumId={id}
                            />}
                        </div>
                        {indexMediaClicked !== null &&
                            <MediaCarousel
                                media={media}
                                initialSlide={indexMediaClicked}
                                handleClose={() => window.history.back()}
                                handleSettings={setSettingId}
                                onlyReadAlbum={!emailUser}
                            />}
                        <MediaSettings
                            visible={settingId !== null && gallery !== undefined}
                            handleClose={() => setSettingId(null)}
                            handleDelete={handleDeleteFile}
                            handleSetCover={handleSetCover}
                            handleToggleFavorite={handleToggleFavorite}
                            media={media[indexMediaClicked]}
                            isOwner={isOwner}
                        />
                    </React.Fragment>
            }

            {showToast && <ToastInfo children={messageToast} warningImageFormat={true} />}

            {
                data &&
                <Modals
                    name={data.name}
                    onChangeNameAlbum={(e) => setData({ ...data, name: e.target.value })}
                    confirmChangeName={confirmChangeName}
                />
            }
        </Page>
    );
}
import React, { useEffect } from "react";
import { Page } from '../../components/page';
import { Modal, ToastDanger, Button } from "../../ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getAllUrlParams } from "../../libs";
import { collaboratorService, albumService } from "../../store/services";

export const AlbumPrivacyValues = {
    PRIVATE: 0,
    PUBLIC_READ: 1,
    PUBLIC_WRITE: 2,
}

export const CollaboratorPermissions = {
    READ: "read",
    WRITE: "write",
}

export function AlbumPrivacy() {
    const { id, privacy, title } = getAllUrlParams();
    const [shareCode, setShareCode] = React.useState();
    const [isPublicRead, setIsPublicRead] = React.useState(parseInt(privacy) === AlbumPrivacyValues.PUBLIC_READ || parseInt(privacy) === AlbumPrivacyValues.PUBLIC_WRITE);
    const [isPublicWrite, setIsPublicWrite] = React.useState(parseInt(privacy) === AlbumPrivacyValues.PUBLIC_WRITE);
    const [modalData, setModalData] = React.useState({
        title: '',
        text: '',
    });
    const [showToast, setShowToast] = React.useState(false);
    const [errorCode, setErrorCode] = React.useState(-1);
    const [collaborators, setCollaborators] = React.useState([]);
    const [newCollaborator, setNewCollaborator] = React.useState('');

    useEffect(() => {
        if (id) {
            collaboratorService.get(id).then((res) => {
                if (res && res.length > 0) {
                    setCollaborators(res);
                } else {
                    if (res.length === 0) {
                        setCollaborators([]);
                    } else {
                        setShowToast(true);
                    }
                }
            });
        }
    }, [id]);

    const changePrivacyInUrl = (newPrivacy) => {
        // change privacy value in url without add history
        const url = new URL(window.location.href);
        url.searchParams.set('privacy', newPrivacy);
        window.history.replaceState({}, '', url);
    }

    const enablePublicRead = () => {
        setIsPublicRead(!isPublicRead);
        if(isPublicRead) {
            // if disable read, disable also write
            setIsPublicWrite(false);
        }
        const newPrivacy = isPublicRead ? AlbumPrivacyValues.PRIVATE : AlbumPrivacyValues.PUBLIC_READ;
        albumService.changePrivacy(id, newPrivacy).then((res) => {
            if (res.status) {
                setShowToast(true);
                setIsPublicRead(isPublicRead);
            }
        });
        changePrivacyInUrl(newPrivacy);
    };

    const enablePublicWrite = () => {
        let newPrivacy = AlbumPrivacyValues.PRIVATE;
        if(isPublicWrite) {
            // disable publicWrite
            setIsPublicWrite(false);
            newPrivacy = AlbumPrivacyValues.PUBLIC_READ;
        } else {
            // enable publicWrite
            setIsPublicWrite(true);
            setIsPublicRead(true);
            newPrivacy = AlbumPrivacyValues.PUBLIC_WRITE;
        }
        albumService.changePrivacy(id, newPrivacy).then((res) => {
            if (res.status) {
                setShowToast(true);
                setIsPublicRead(isPublicRead);
            }
        });
        changePrivacyInUrl(newPrivacy);
    }

    const changePermission = (user) => {
        collaboratorService.update(id,
            user.email,
            user.permissions === CollaboratorPermissions.WRITE ? CollaboratorPermissions.READ : CollaboratorPermissions.WRITE).then((res) => {
                if (res && res.email) {
                    setCollaborators(collaborators.map((collaborator) => {
                        if (collaborator.email === user.email) {
                            collaborator.permissions = collaborator.permissions === CollaboratorPermissions.WRITE ? CollaboratorPermissions.READ : CollaboratorPermissions.WRITE;
                        }
                        return collaborator;
                    }));
                } else {
                    setShowToast(true);
                }

            });
    }

    const addCollaborator = () => {
        collaboratorService.add(id, newCollaborator.toLowerCase()).then((res) => {
            if (res && res.email) {
                setCollaborators([...collaborators, { email: newCollaborator, permissions: CollaboratorPermissions.READ }]);
                setNewCollaborator('');
            } else {
                return res.json().then((data) => {
                    setErrorCode(data.errorCode);
                    setShowToast(true);
                });
            }
        });
    }

    const deleteCollaborator = (email) => {
        collaboratorService.delete(id, email).then((res) => {
            if (res && res.status === 204) {
                setCollaborators(collaborators.filter((collaborator) => collaborator.email !== email));
            } else {
                setShowToast(true);
            }
        });
    }

    const enableShareCode = (value) => {
        // generate random code of 10 characters alphanumeric in capital letters
        if (!isPublicRead) {
            const code = Math.random().toString(36).substring(2, 12).toUpperCase();
            setShareCode(code);
        }
        else {
            setShareCode('');
        }
        setIsPublicRead(!isPublicRead);
    };

    const handleCopyCode = () => {
        navigator.clipboard.writeText(shareCode);
    };

    const handleHelpShareAll = () => {
        setModalData({
            title: 'Visualizzazione pubblica',
            text: 'Se attivi questa opzione, il tuo album, e le foto in esso contenute, sarnno visibili a tutti gli utenti con cui condividerai il link dell\'album o se l\'utente avvicina lo smartphone alla tua smart foto.',
        });
    }

    const handleHelpWriteAll = () => {
        setModalData({
            title: 'Modifica pubblica',
            text: 'Se attivi questa opzione, il tuo album, e le foto in esso contenute, sarnno visibili a tutti gli utenti con cui condividerai il link dell\'album o se l\'utente avvicina lo smartphone alla tua smart foto. Inoltre gli utenti potranno caricare foto o video.',
        });
    }

    const handleHelpShareCode = () => {
        setModalData({
            title: 'Codice condivisione',
            text: 'Se attivi questa opzione, il tuo album sarà visibile solo a chi inserirà il codice di condivisione. Il codice di condivisione verrà generato nuovo, e in maniera casuale, ogni volta che attivi l\'opzione.',
        });
    }

    const handleHelpShareUsers = () => {
        setModalData({
            title: 'Utenti Autorizzati a Visualizzare',
            text: 'Inserisci l\'email dell\'utente che vuoi autorizzare a visualizzare il tuo album. L\'utente dovrà essere registrato a Kangury per poter essere autorizzato.\n\nPuoi anche decidere se l\'utente può solo visualizzare l\'album o se può anche aggiungere foto.',
        });
    }

    return (
        <Page title={title}
            visibleBottomNavigation={false}>
            <div className="container mt-1 fs-4">
                <h2 className="text-center">
                    Privacy Album
                </h2>
                <div className="d-flex justify-content-between align-items-center form-check form-switch p-1">
                    <div>
                        <span>Visualizzazione pubblica</span>
                        <FontAwesomeIcon icon="fa-regular fa-circle-question" className="bg-primary rounded-5 cursor-pointer ms-3" onClick={handleHelpShareAll} data-bs-toggle="modal" data-bs-target="#privacyModal" />
                    </div>
                    <div>
                        <input className="form-check-input cursor-pointer" type="checkbox" id="flexSwitchCheckDefault" onClick={enablePublicRead} value={isPublicRead} checked={isPublicRead} />
                    </div>
                </div>
                <div className="d-flex justify-content-between align-items-center form-check form-switch p-1">
                    <div>
                        <span>Modifica pubblica</span>
                        <FontAwesomeIcon icon="fa-regular fa-circle-question" className="bg-primary rounded-5 cursor-pointer ms-3" onClick={handleHelpWriteAll} data-bs-toggle="modal" data-bs-target="#privacyModal" />
                    </div>
                    <div>
                        <input className="form-check-input cursor-pointer" type="checkbox" id="flexSwitchCheckDefault" onClick={enablePublicWrite} value={isPublicWrite} checked={isPublicWrite} />
                    </div>
                </div>

                {/* <div className="d-flex justify-content-between align-items-center form-check form-switch p-1 mt-2">
                    <div>
                        <span>Codice condivisione</span>
                        <FontAwesomeIcon icon="fa-regular fa-circle-question" className="bg-primary rounded-5 cursor-pointer ms-3" onClick={handleHelpShareCode} data-bs-toggle="modal" data-bs-target="#privacyModal" />
                    </div>
                    <div>
                        <input className="form-check-input cursor-pointer" type="checkbox" id="flexSwitchCheckDefault" onClick={enableShareCode} value={isPublic} />
                    </div>
                </div>
                <SharedCodeField
                    value={shareCode}
                    handleClick={handleCopyCode} /> */}
            </div>
            <div className="border-top border-2 mt-3" />
            <div className="container mt-3 text-center h-75">
                <p className="fs-3">
                    Utenti Autorizzati a Visualizzare
                    <FontAwesomeIcon icon="fa-regular fa-circle-question" className="bg-primary rounded-5 cursor-pointer ms-3" onClick={handleHelpShareUsers} data-bs-toggle="modal" data-bs-target="#privacyModal" />
                </p>
                <div className="d-flex mt-3 justify-content-between align-items-center">
                    <input type="email" className="form-control flex-grow-1"
                        placeholder="Aggiungi utente (email)"
                        value={newCollaborator}
                        onChange={(e) => setNewCollaborator(e.target.value)} />
                    <FontAwesomeIcon
                        onClick={addCollaborator}
                        icon="fa-solid fa-circle-plus"
                        className="text-primary rounded-5 border-primary cursor-pointer bg-dark ms-2"
                        size="2x" />
                </div>
                {collaborators && collaborators.length > 0 ?
                    <>
                        <div className="d-flex mt-3 justify-content-between align-items-center">
                            <div>
                                Email utente
                            </div>
                            <div>
                                modifica
                            </div>
                        </div>
                        <div className="border border-2 mt-1 overflow-auto">
                            {collaborators.map((user, index) => (
                                <div className="d-flex justify-content-between align-items-center border-bottom border-1 p-2" key={index}>
                                    <div className="d-flex align-items-center">
                                        <FontAwesomeIcon
                                            onClick={() => deleteCollaborator(user.email)}
                                            icon="fa-solid fa-circle-minus"
                                            className=" p-0 rounded-5 cursor-pointer bg-dark text-danger me-2" size="lg" />
                                        <p className="m-0">
                                            {user.email}
                                        </p>
                                    </div>
                                    <div className="form-check form-switch">
                                        <input className="form-check-input cursor-pointer" type="checkbox" id="flexSwitchCheckDefault2"
                                            onClick={() => changePermission(user)}
                                            value={user.permissions === CollaboratorPermissions.WRITE}
                                            checked={user.permissions === CollaboratorPermissions.WRITE} />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </>
                    : <div className="text-center mt-3">
                        Nessun utente autorizzato
                    </div>}
            </div>
            <Modal
                title={modalData.title}
                text={modalData.text}
                secondaryText="Chiudi"
                id="privacyModal"
            />
            {showToast &&
                <div className="ps-3 pe-3">
                    <ToastDanger>
                        <div className="d-flex justify-content-between align-items-center ps-2 pe-2">
                            <span>
                                {translateErrorCode(errorCode)}
                            </span>
                            <FontAwesomeIcon icon="fa-regular fa-x"
                                onClick={() => setShowToast(false)}
                                className="text-primary cursor-pointer text-white"
                            />
                        </div>
                    </ToastDanger>
                </div>}
        </Page>
    );
}

const translateErrorCode = (errorCode) => {
    switch (errorCode) {
        case 1:
            return 'Email non trovata';
        case 2:
            return 'Non puoi aggiungere te stesso come collaboratore';
        case 3:
            return 'Collaboratore già presente';
        default:
            return 'Errore sconosciuto, controlla di essere connesso ad internet o riprova più tardi.';
    }
}
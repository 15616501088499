import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Page } from '../../components';
import { userService } from '../../store/services';
import { Button, ToastDanger, PasswordField } from '../../ui';
import { getAllUrlParams } from '../../libs';

const ERRORS = {
    email: "Email già registrata",
    fillFields: "Compila tutti i campi",
    generic: "Registrazione fallita, riprova più tardi o contatta l'assistenza",
    password: "Le password non coincidono",
    passwordLength: "La password deve essere lunga almeno 6 caratteri",
}

export function Registration() {
    const { email } = getAllUrlParams();
    const navigate = useNavigate();
    const [showToast, setShowToast] = React.useState('');
    const [showFinalMessage, setShowFinalMessage] = React.useState(false);
    const [data, setData] = React.useState({
        email: email || '',
        password: '',
        password2: '',
        acceptTerms: false,
        sendNotification: false,
    });
    const [loading, setLoading] = React.useState(false);

    const handleRegistration = () => {
        setLoading(true);
        const email = data.email.toLowerCase().trim();
        if (!email || !data.password || !data.password2 || !data.acceptTerms) {
            setShowToast(ERRORS.fillFields);
            return;
        }
        if (!email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
            setShowToast(ERRORS.email);
            return;
        }
        if (data.password.length < 6) {
            setShowToast(ERRORS.passwordLength);
            return;
        }
        if (data.password !== data.password2) {
            setShowToast(ERRORS.password);
            return;
        }
        const emailLowerCaseWithoutSpace = email.toLowerCase().replace(/\s/g, '');
        userService.register(emailLowerCaseWithoutSpace, data.password, data.sendNotification)
            .then(data => {
                setLoading(false);
                if (data.user) {
                    setShowFinalMessage(true);
                }
                else {
                    return data.json();
                }
            })
            .then(data => {
                if (data.email)
                    setShowToast(ERRORS.email);
                else
                    setShowToast(ERRORS.generic);
            })
            .catch(err => {
                setShowToast(ERRORS.generic);
            })
    }

    const goToPrivacyPage = () => {
        navigate(`/external-links?url=&title=Privacy Policy`);
    }

    const goToAssistancePage = () => {
        navigate(`/assistance?contacts=true`);
    }

    return (
        <Page visibleBottomNavigation={false} loading={loading}
            title={"Registrazione"}>
            {!showFinalMessage ?
                <div className='container p-3'>
                    {showToast && <ToastDanger>
                        {showToast}
                    </ToastDanger>}
                    <input type="text" placeholder="Email"
                        className={`w-100 mt-3 form-control rounded-5 ${(showToast === ERRORS.fillFields && !data.email) || showToast === ERRORS.email ? 'is-invalid' : ''}`}
                        value={data.email} onChange={(e) => setData({ ...data, email: e.target.value })} />
                    <PasswordField value={data.password} setValue={(value) => setData({ ...data, password: value })} placeholder="Password" className={`${showToast === ERRORS.passwordLength || (showToast === ERRORS.fillFields && !data.password) ? 'border-danger' : ''}`} />
                    <PasswordField value={data.password2} setValue={(value) => setData({ ...data, password2: value })} placeholder="Conferma password" className={`${showToast === ERRORS.password || (showToast === ERRORS.fillFields && !data.password2) ? 'border-danger' : ''}`} />
                    <div className="form-check mt-3">
                        <input className="form-check-input" type="checkbox" id="check1" value={data.sendNotification} onChange={(e) => setData({ ...data, sendNotification: e.target.checked })} />
                        <label className="form-check-label" htmlFor="check1">
                            <span className='fw-bold'>Sì, voglio ricevere sconti esclusivi</span> e conoscere i nuovi prodotti in anteprima!
                        </label>
                    </div>
                    <div className="form-check">
                        <input className={`form-check-input ${showToast === ERRORS.fillFields && !data.acceptTerms ? 'is-invalid' : ''}`}
                            type="checkbox" id="check2" value={data.acceptTerms} onChange={(e) => setData({ ...data, acceptTerms: e.target.checked })} />
                        <label className="form-check-label" htmlFor="check2">
                            Accetto il trattamento dei dati inseriti secondo quanto riportato nella pagina della <span className='text-decoration-underline' onClick={goToPrivacyPage}>Privacy Policy</span>; dichiaro inoltre di essere maggiorenne.
                        </label>
                    </div>
                    <Button onClick={handleRegistration} otherClass={"mt-3 p-1"}>
                        Registrati
                    </Button>
                </div>
                :
                <div className='container p-3 h-100 d-flex flex-column justify-content-center align-items-center'>
                    <div className='text-center fs-2 fw-bold'>
                        Registrazione quasi conclusa
                    </div>
                    <div className='text-center fs-4'>
                        Abbiamo inviato un messaggio e-mail all'indirizzo "<span className='fst-italic'>{data.email}</span>" segui il collegamento in esso contenuto per concludere la procedura.
                    </div>
                    <div className='text-center fs-5 mt-4'>
                        Non hai ricevuto nulla? Prova a controllare tra la posta indesiderata, oppure scrivi al nostro <span className='text-decoration-underline' onClick={goToAssistancePage}>servizio clienti</span>.
                    </div>
                </div>}
        </Page>
    );
}
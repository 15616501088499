import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { BottomButton, ListImages, Page, UploadButton, UploadNativeButton } from '../../components';
import { priceModifiers, calculatePrice, getAllUrlParams, getModifierByProperties, getQuantityOfProductInCart, getOldestExpirationDate, showCropAlert, showResolutionAlert } from '../../libs';
import { cartActions, productDataActions, templateDataActions } from '../../store/actions';
import { imageService } from '../../store/services';
import { ProgressbarCircle, AlertFixed, Button } from '../../ui';
import { ProductModals } from './modals';

export function Product() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { subCategoryDict, general, propertyNotPresentAnymore } = useSelector(state => state.mainData);
    const { template } = useSelector(state => state.templateData);
    const { imagesList, editableProperty } = useSelector(state => state.productData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const productsInCart = useSelector(state => state.cartData)?.products || [];
    const { subCategoryId, productId, templateId, properties: _properties, propertiesId, id } = getAllUrlParams();
    const product = subCategoryDict && subCategoryDict[subCategoryId]?.subProductDict[productId];
    const [imageUploading, setImageUploading] = React.useState(false);
    const [imagesUploaded, setImagesUploaded] = React.useState(0);
    const [imagesNeedUpload, setImagesNeedUpload] = React.useState(0);
    const imagesAlreadyUploadedRef = React.useRef(0);
    const imagesSelectedRef = React.useRef(0);
    const [percentage, setPercentage] = React.useState(-1);
    const [showAlert, setShowAlert] = React.useState(false);
    const [alertMessage, setAlertMessage] = React.useState('');
    const speedActive = localStorage.getItem('speedActive') === 'true' && (product?.processingType === 1 || product?.processingType === 2) ? true : false;

    const [warnForBuyShowed, setWarnForBuyShowed] = React.useState(false);
    const [nativePermissionGranted, setNativePermissionGranted] = React.useState(localStorage.getItem('permissionGranted') === 'true');
    const showScissorInfo = React.useMemo(() => {
        return showCropAlert(imagesList, template, product?.processingType);
    }, [imagesList, product?.processingType, template]);
    const showResolutionInfo = React.useMemo(() => {
        return showResolutionAlert(imagesList, template);
    }, [imagesList, template]);

    const properties = React.useMemo(() => {
        if (propertyNotPresentAnymore && _properties && propertyNotPresentAnymore.length >= 0) {
            const properties = _properties.split(';').map(property => parseInt(property));
            let propertiesReturn = '';
            properties.forEach(property => {
                const index = propertyNotPresentAnymore.findIndex(propertyNotPresent => propertyNotPresent.value === property);
                if (index === -1) {
                    // property is present
                    propertiesReturn += `${property};`
                } else {
                    // property is not present, get first value of property in product
                    const propertyFromProduct = product.properties.find(propertyProduct => propertyProduct.id === propertyNotPresentAnymore[index].id);
                    propertiesReturn += `${propertyFromProduct.propertyValues[0].id};`
                    // modify url without reload page
                    const newPath = window.location.href.replace(`${propertyNotPresentAnymore[index].value}`, `${propertyFromProduct.propertyValues[0].id}`);
                    window.history.replaceState("", "", newPath);
                }
            });
            return propertiesReturn.slice(0, -1);
        }
        return _properties;

    }, [_properties, product, propertyNotPresentAnymore]);

    const maxQuantity = React.useMemo(() => {
        if (product && product.processingType === 1) return 9999;
        let quantity = 0;
        if (product && template) {
            template.forEach(page => {
                page.designObjects.every(object => {
                    if (object.image.type === 0) {
                        quantity++;
                        return false;
                    }
                    return true;
                });
            });
        }
        if (quantity !== 0)
            return quantity;
        return 9999;
    }, [product, template]);
    const propertiesEditable = React.useMemo(() => {
        if (product && product.properties) {
            return product.properties.filter(property => property.editInEditor);
        }
        return [];
    }, [product]);

    React.useEffect(() => {
        if (window.ReactNativeWebView)
            window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'askPermissions' }));

        const messageHandler = (event) => {
            if (event.data && typeof event.data === 'string') {
                const data = JSON.parse(event.data);
                if (data.type === 'permission') {
                    const granted = data.granted;
                    localStorage.setItem('permissionGranted', granted);
                    setNativePermissionGranted(granted);
                }
            }
        }
        window.addEventListener('message', messageHandler);
        document.addEventListener('message', messageHandler);
        return () => {
            window.removeEventListener('message', messageHandler);
            document.removeEventListener('message', messageHandler);
        }
    }, []);

    React.useEffect(() => {
        dispatch(productDataActions.unlockClean());
    }, [dispatch]);

    React.useEffect(() => {
        if (editableProperty) {
            const propertyChanged = propertiesEditable.find(property => property.id === editableProperty.id);
            let oldPropertyId = propertyChanged.propertyValues.find(propertyValue => properties.includes(propertyValue.id)).id;
            const newPath = window.location.href.replace(`${oldPropertyId}`, `${editableProperty.newValue}`);
            dispatch(productDataActions.clearEditableProperty());
            window.history.replaceState("", "", newPath);
        }
    }, [dispatch, editableProperty, properties, propertiesEditable]);

    React.useEffect(() => {
        if (imagesList.length === 0)
            dispatch(productDataActions.get())
    }, [dispatch, imagesList.length]);

    React.useEffect(() => {
        if (product)
            dispatch(templateDataActions.get(product, templateId, properties?.split(';')));
    }, [product, templateId, properties, dispatch]);

    const modifiers = React.useMemo(() => {
        return getModifierByProperties(properties?.split(";") || [], product);
    }, [product, properties]);

    const bottomPrice = React.useMemo(() => {
        if (!product) return 0;
        let imagesInCart = getQuantityOfProductInCart({ cart: productsInCart, productId, processingType: product.processingType });
        imagesInCart += imagesList.reduce((acc, image) => acc + image.quantity, 0);
        let { originalPrice, kanguryPrice } = calculatePrice({ prices: product.prices, imagesList, imagesInCart, processingType: product.processingType, kanguryPrices: product.kanguryPrices });
        if (modifiers) {
            kanguryPrice = kanguryPrice + priceModifiers(originalPrice, modifiers);
        }
        return kanguryPrice.toFixed(2).replace(".", ",") + " €";
    }, [imagesList, modifiers, product, productId, productsInCart]);

    const totalQuantity = React.useMemo(() => {
        return imagesList.reduce(
            (acc, image) => {
                if (image.originalImage || image.image || image.native)
                    return acc + image.quantity
                return acc;
            },
            0
        );
    }, [imagesList]);

    const getTemplateFromProperties = React.useCallback(() => {
        // split properties to array and cast to number
        if (!properties) {
            if (product?.templates.length > 0)
                return product.templates[0];
            return null
        };
        const propertiesUsed = properties?.split(';').map(property => parseInt(property));
        const value = product.templates.filter(template => {
            // if template.propertyValueFilter include one property in propertiesUsed, return false
            return template.propertyValueFilter?.every(property => {
                return !propertiesUsed.includes(property);
            });
        })
        if (value.length) return value[0];
        return null;
    }, [product?.templates, properties]);

    const dispatchAddToCart = useCallback((imageCart, totalImages, imagesList, imageCartProp) => {
        let url = window.location.search;
        let oldestExipirationDate = getOldestExpirationDate(imagesList);
        // remove id paramenter from url
        url = url.replace(/id=[^&]*&?/, '');
        dispatch(cartActions.add({
            productId,
            templateId: templateId || getTemplateFromProperties()?.id || null,
            template,
            general,
            properties,
            propertiesId,
            subCategoryId,
            countInCart: 1,
            imagesList,
            totalImages,
            editable: product?.processingType !== 0,
            prices: product?.prices,
            kanguryPrices: product?.kanguryPrices,
            title: product?.name,
            image: imageCart,
            imageCartProp,
            processingType: product?.processingType,
            productUrl: url,
            id: Date.now(),
            expirationDate: oldestExipirationDate
        }, parseInt(id)));
        dispatch(productDataActions.clean());
        navigate("/cart");
    }, [dispatch, productId, templateId, getTemplateFromProperties, template, general, properties, propertiesId, subCategoryId, product?.processingType, product?.prices, product?.kanguryPrices, product?.name, id, navigate]);

    const showErrorAlert = (index) => {
        // check if internet connection is lost
        setAlertMessage(`Upload dell'immagine ${index + 1} fallito, controlla la tua connessione internet o l'estensione del file`);
        setShowAlert(true);
        setPercentage(-1);
    }

    const updatePercentage = useCallback((imagesList, index, totalImages) => {
        // in some product the imagesList not contain real user image like in calendar
        // for this reason I nead to count real image in the first part of this function
        let notImagesListUntilIndex = 0;
        let notImagesListTotal = 0;
        if (product.processingType === 4) {
            notImagesListTotal = imagesList.filter(image => !image.originalImage && !image.image).length;
            for (let i = 0; i < index; i++) {
                const validImage = imagesList[i].originalImage || imagesList[i].image;
                if (!validImage) {
                    notImagesListUntilIndex++;
                }
            }
        }
        setImagesUploaded(index + 1 - notImagesListUntilIndex);
        setPercentage(Math.round((index + 1 - notImagesListUntilIndex) / (totalImages - notImagesListTotal) * 100));
    }, [product?.processingType]);

    const stopUploadRef = React.useRef(false);
    const uploadImages = async (imagesList, imageCart, totalImages, totalImagesQuantity, imageCartProp = -1, index = 0, imageUploaded = 0) => {
        if (stopUploadRef.current) {
            return;
        }
        updatePercentage(imagesList, index, totalImages);
        if (index === imagesList.length) {
            dispatchAddToCart(imageCart, totalImagesQuantity, imagesList, imageCartProp, imageUploaded);
            return
        }
        // not valid for product with preview not updatable
        const validImage = imagesList[index].originalImage || imagesList[index].image;
        if ((!imagesList[index].id || !imagesList[index].uploaded) && validImage) {
            const urlImage = imagesList[index].originalImage || imagesList[index].image;
            // cast image from urlImage to base64, urlImage = URL.createObjectURL
            const dataImage = await fetch(urlImage);
            const b = await dataImage.blob();
            const reader = new FileReader();
            reader.readAsDataURL(b);
            reader.onloadend = async () => {
                const base64data = reader.result;
                const image = base64data.split(',')[1];
                const response = await imageService.post({
                    data: image,
                    src: imagesList[index].imageName,
                });
                if (response.id) {
                    imagesList[index].id = response.id;
                    imagesList[index].fingerPrint = response.fingerPrint;
                    imagesList[index].uploaded = true;
                    imagesList[index].expirationDate = response.expirationDate;
                    imagesList[index].native = false;

                    if (imageCartProp === -1)
                        uploadImages(imagesList, imageCart, totalImages, totalImagesQuantity, { id: response.id, fingerPrint: response.fingerPrint }, index + 1, imageUploaded + 1);
                    else
                        uploadImages(imagesList, imageCart, totalImages, totalImagesQuantity, imageCartProp, index + 1, imageUploaded + 1);

                } else {
                    // try to upload the same image
                    uploadImages(imagesList, imageCart, totalImages, totalImagesQuantity, imageCartProp, index, imageUploaded);
                }

            }
        }
        else {
            if (imageCartProp === -1 && validImage)
                uploadImages(imagesList, imageCart, totalImages, totalImagesQuantity, { id: imagesList[index].id, fingerPrint: imagesList[index].fingerPrint }, index + 1);
            else
                uploadImages(imagesList, imageCart, totalImages, totalImagesQuantity, imageCartProp, index + 1);
        }
    }

    const imagesListNativeRef = React.useRef(null);
    const imageCartNativeRef = React.useRef(null);
    const totalImagesNativeRef = React.useRef(null);
    const imageCartPropNativeRef = React.useRef(null);
    const idsNativeRef = React.useRef(null);
    const uploadImagesNative = async (imagesList) => {
        const ids = imagesList.filter(image => !image.uploaded && (image.originalImage || image.image || image.native)).map(image => image.id);
        imagesListNativeRef.current = imagesList;
        imagesListNativeRef.current.every((image, index) => {
            if (image.image || image.originalImage) {
                imageCartNativeRef.current = image.image || image.originalImage;
                return false
            }
            return true;
        });
        totalImagesNativeRef.current = imagesListNativeRef.current.filter(image => (image.originalImage || image.image || image.native))
            .reduce((acc, image) => acc + image.quantity, 0);
        idsNativeRef.current = ids;
        setImagesNeedUpload(ids.length);
        setImagesUploaded(0);
        if (ids.length > 0) {
            window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'uploadImages', ids }));
        } else {
            dispatchAddToCart(imageCartNativeRef.current, totalImagesNativeRef.current, imagesListNativeRef.current, imageCartPropNativeRef.current, -1);
            window.visibleImages = undefined;
            window.scrollTopSaved = 0
        }
    }

    const cancelUpload = () => {
        stopUploadRef.current = true;
        setTimeout(() => {
            setPercentage(-1);
            setImagesUploaded(0);
            setImagesNeedUpload(0);
        }, 100);
    }

    React.useEffect(() => {
        const messageHandler = (event) => {
            const data = JSON.parse(event.data);
            if (data.type === 'uploadImageError') {
                const index = data.index;
                showErrorAlert(index);
            }
            if (data.type === 'uploadImageSuccess') {
                const { id, fingerPrint, expirationDate, oldId } = data;
                // get index of image with oldId
                const indexOldId = imagesListNativeRef.current.findIndex(image => image.id === oldId);
                imagesListNativeRef.current[indexOldId].id = id;
                imagesListNativeRef.current[indexOldId].fingerPrint = fingerPrint;
                imagesListNativeRef.current[indexOldId].expirationDate = expirationDate;
                imagesListNativeRef.current[indexOldId].uploaded = true;
                imagesListNativeRef.current[indexOldId].native = true;
                imagesListNativeRef.current[indexOldId].image = 'uploaded';
                imagesListNativeRef.current[indexOldId].originalImage = 'uploaded';
                if (imageCartPropNativeRef.current === null) {
                    imageCartPropNativeRef.current = { id, fingerPrint };
                }
                setImagesUploaded(prev => {
                    setPercentage(Math.round((prev + 1) / idsNativeRef.current.length * 100));
                    return prev + 1;
                });
                if (imagesUploaded === idsNativeRef.current.length - 1) {
                    setTimeout(() => {
                        dispatchAddToCart(imageCartNativeRef.current, totalImagesNativeRef.current, imagesListNativeRef.current, imageCartPropNativeRef.current, -1);
                    }, 500);
                }
            }
        }
        if (window.ReactNativeWebView && nativePermissionGranted) {
            window.addEventListener('message', messageHandler);
            document.addEventListener('message', messageHandler);
        }
        return () => {
            if (window.ReactNativeWebView && nativePermissionGranted) {
                window.removeEventListener('message', messageHandler);
                document.removeEventListener('message', messageHandler);
            }
        }
    }, [dispatchAddToCart, updatePercentage, nativePermissionGranted, imagesUploaded]);

    const handleAddToCart = () => {
        if (!imagesList.length) return;
        if (product?.processingType === 2 && !warnForBuyShowed) {
            // in case of processing type 2 alert user that can choose more photos (example: vintage photos)
            const countImagesList = imagesList.reduce((acc, image) => {
                return acc + image.quantity;
            }, 0);
            if (countImagesList < template.length) {
                setWarnForBuyShowed(true);
                setAlertMessage(`Attenzione non hai caricato tutte le foto, in totale puoi caricarne fino a ${template.length}, puoi ignorare questo avviso e procedere comunque`);
                setShowAlert(true);
                return;
            }
        }
        const totalImagesQuantity = imagesList.filter(image => (image.originalImage || image.image || image.native))
            .reduce((acc, imagesList) => acc + imagesList.quantity, 0);
        const totalImages = imagesList.length;
        let imageCart = '';
        imagesList.every(image => {
            if (image.image || image.originalImage) {
                imageCart = image.image || image.originalImage;
                return false;
            }
            return true;
        });
        setPercentage(0);
        setImagesUploaded(0);
        stopUploadRef.current = false;

        setTimeout(() => {
            if (window.ReactNativeWebView && nativePermissionGranted && !speedActive) {
                uploadImagesNative(imagesList, imageCart, totalImages, totalImagesQuantity);
            }
            else {
                setImagesNeedUpload(imagesList.filter(image => (image.originalImage || image.image)).length);
                const allImagesWithImageOrOriginalImageNotUploaded = imagesList.filter(image => ((image.originalImage || image.image) && !image.uploaded));
                const allImagesWithImageOrOriginalImageUploaded = imagesList.filter(image => ((image.originalImage || image.image) && image.uploaded));
                imagesAlreadyUploadedRef.current = allImagesWithImageOrOriginalImageUploaded.length;
                imagesSelectedRef.current = allImagesWithImageOrOriginalImageNotUploaded.length;
                uploadImages(imagesList, imageCart, totalImages, totalImagesQuantity);
            }
        }, 100);
    }

    const disableBuyButton = React.useMemo(() => {
        if (!product?.processingType) return true; // if product is not loaded
        if (imagesList.length === 0) return true;
        if (imageUploading) return true;
        if ((product.processingType === 1 || product.processingType === 2) && imagesList.length > 0) {
            return false;
        }
        if (product.processingType === 3 || product.processingType === 4) {
            // return false if there is a null image in imagesList
            return imagesList.some(image => (!image.image || !image.originalImage) && image.needImage);
        }
        return true;
    }, [imageUploading, imagesList, product]);

    const centerIfOneImage = React.useMemo(() => {
        if (imagesList.length === 1 && (product?.processingType === 3 || product?.processingType === 4)) return 'h-100 justify-content-center';
        return '';
    }, [imagesList.length, product?.processingType]);

    const icon = speedActive ? 'fa-solid' : 'fa-regular';

    return (
        <Page loading={!template} title={product?.name}
            scrollButtons={true}
            customBottomSection={
                <BottomButton
                    content={id ?
                        <div>
                            <FontAwesomeIcon icon="fa-regular fa-floppy-disk" className="me-2" />
                            Salva e Prosegui
                        </div> :
                        <div>
                            <FontAwesomeIcon icon="fa-regular fa-bag-shopping" className="me-2" />
                            Aggiungi al Carrello
                        </div>}
                    onClick={handleAddToCart}
                    contentUp={<div className='text-center'>
                        {showScissorInfo && <div className='text-primary fw-bold text-decoration-underline pt-2' data-bs-toggle="modal" data-bs-target={"#scissorsModal"}>
                            <span className='me-2'>
                                <FontAwesomeIcon icon="fa-regular fa-circle-info" className='text-white bg-primary rounded-circle' />
                            </span>
                            <span>Ci sono delle foto ritagliate</span>
                        </div>}
                        {showResolutionInfo && <div className='text-danger fw-bold text-decoration-underline pt-2' data-bs-toggle="modal" data-bs-target={"#resolutionModal"}>
                            <span className='me-2'>
                                <FontAwesomeIcon icon="fa-solid fa-triangle-exclamation" className='text-danger bg-white' />
                            </span>
                            <span>Ci sono delle foto con una risoluzione insufficiente</span>
                        </div>}
                        <div>{`Immagini: ${totalQuantity} - Totale: ${bottomPrice}`}</div>
                    </div>}
                    disabled={disableBuyButton} />}>
            <div className={`container p-3 d-flex flex-column align-items-center pt-4 gap-2 ${centerIfOneImage}`}>
                <div className='d-flex justify-content-between align-items-top gap-2 w-100'>
                    {(window.ReactNativeWebView && nativePermissionGranted && (product?.processingType === 1 || product?.processingType === 2)) &&
                        <div className='mt-2' data-bs-toggle="modal" data-bs-target={speedActive ? "#disactiveSpeedModal" : "#activeSpeedModal"}>
                            <FontAwesomeIcon icon={`${icon} fa-bolt-lightning`} className="fs-1 text-primary" />
                        </div>}
                    <div className='flex-grow-1'>
                        {// check if web app run in react native
                            (window.ReactNativeWebView && nativePermissionGranted && (product?.processingType === 1 || product?.processingType === 2)) && !speedActive &&
                            <UploadNativeButton
                                maxQuantity={maxQuantity - totalQuantity}
                                setImageUploading={setImageUploading}
                                navigate={navigate}
                                imagesLength={imagesList.length}
                                disable={maxQuantity <= totalQuantity}
                                processingType={product?.processingType}
                                template={template} />}
                        {((!window.ReactNativeWebView || !nativePermissionGranted || speedActive) && (product?.processingType === 1 || product?.processingType === 2)) &&
                            <UploadButton maxQuantity={maxQuantity - totalQuantity}
                                setImageUploading={setImageUploading}
                                resetWarn={() => setWarnForBuyShowed(false)}
                                active={maxQuantity > totalQuantity}
                                processingType={product?.processingType}
                                template={template}
                            />}
                    </div>

                </div>
                {template && <ListImages
                    processingType={product?.processingType}
                    imagesLists={imagesList}
                    template={template}
                    isPossibleAdd={totalQuantity < maxQuantity}
                    propertiesEditable={propertiesEditable}
                />}
                {imagesList?.length === 0 &&
                    <div className='text-center w-100 h-100 d-flex flex-column justify-content-center align-items-center p-4'>
                        <div className='mb-1 fs-4 fw-bold'>Sembra un pò vuoto qui...</div>
                        <div className='fs-5 fst-italic'>Premi sul pulsante "Carica Immagini" per creare un prodotto</div>
                    </div>
                }
            </div>
            {percentage >= 0 &&
                <div className='position-absolute w-100 h-100 d-flex justify-content-center align-items-center top-0 start-0 z-1000 flex-column bg-white '>
                    <ProgressbarCircle percentage={percentage} />
                    <h2 className='mt-4'>{imagesUploaded} / {imagesNeedUpload}</h2>
                    <h1 className='mt-4'>Caricamento immagini...</h1>
                    <h5 className='mt-4 text-danger pe-4 ps-4 text-center'>Non chiudere l'app e assicurarsi di avere la connessione disponibile</h5>
                    <div className="position-absolute bottom-0 p-4 w-100">
                        <Button otherClass="btn-secondary text-white" onClick={cancelUpload}>
                            Annulla
                        </Button>
                    </div>
                </div>
            }
            <AlertFixed message={alertMessage} showAlert={showAlert} handleCloseAlert={() => setShowAlert(false)} />
            <ProductModals imagesList={imagesList} speedActive={speedActive} />
        </Page>
    );
}
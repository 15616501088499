import React, { useState } from 'react';

export const UNDEFINED_FILTER = '-';

export function ModalFilters({
    setFilters,
    originalMedia
}) {
    const listOfOwnersWithoutDuplicates = [...new Set(originalMedia.map((media) => media.owner).filter((owner) => owner !== null))];
    const [owner, setOwner] = useState(UNDEFINED_FILTER);
    const [favorite, setFavorite] = useState(UNDEFINED_FILTER);

    const handleConfirm = () => {
        const newFilters = {
            owner: owner,
            favorite: favorite
        };
        setFilters(newFilters);
    };

    const handleReset = () => {
        setOwner(UNDEFINED_FILTER);
        setFavorite(UNDEFINED_FILTER);
        setFilters({ owner: UNDEFINED_FILTER, favorite: UNDEFINED_FILTER });
    };

    return (
        <div
            className="modal fade z-1100"
            id={'filterModal'}
            tabIndex="-1"
            aria-labelledby="mainModalLabel"
        >
            <div className="modal-dialog top-25">
                <div className="modal-content">
                    <div className="modal-header text-center w-100">
                        <h5 className="modal-name fw-bold text-center w-100" id="mainModalLabel">
                            Filtri
                        </h5>
                    </div>
                    <div className="modal-body text-pre-line">
                        <div className="mb-3">
                            <label htmlFor="owner" className="form-label">Filtra per proprietario (chi ha caricato la foto)</label>
                            <select
                                className="form-select"
                                id="owner"
                                value={owner}
                                onChange={(e) => setOwner(e.target.value)}
                            >
                                <option value={UNDEFINED_FILTER}>{UNDEFINED_FILTER}</option>
                                {listOfOwnersWithoutDuplicates.map((owner) => (
                                    <option key={owner} value={owner}>{owner}</option>
                                ))}
                            </select>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="favorite" className="form-label">Filtra per preferiti</label>
                            <select
                                className="form-select"
                                id="favorite"
                                value={favorite}
                                onChange={(e) => setFavorite(e.target.value)}
                            >
                                <option value={UNDEFINED_FILTER}>{UNDEFINED_FILTER}</option>
                                <option value={true}>Preferiti</option>
                                <option value={false}>Non preferiti</option>
                            </select>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <div className="row w-100">
                            <button
                                type="button"
                                className={`btn rounded-5 btn-primary col-6`}
                                onClick={handleConfirm}
                                data-bs-dismiss={"modal"}
                            >
                                Applica
                            </button>
                            <button
                                type="button"
                                className="btn rounded-5 btn-secondary col-6"
                                data-bs-dismiss="modal"
                                onClick={handleReset}
                            >
                                Resetta
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
import { getAllUrlParams, getModifierByProperties, priceModifiers, calculatePrice, getQuantityOfProductInCart } from '.';

/**
 * Function that calculates the total price of the order based on the products in the cart, the courier price and the discounts applied to both products and courier price
 * @param {*} discounts List of discounts applied to the products
 * @param {*} price Total price of the products in the cart
 * @param {*} courierPrice Total price of the courier
 */
export const applyDiscounts = (discounts, price) => {
    let _price = price;
    if (!discounts || discounts.length === 0)
        return { total: price };
    discounts.forEach((discount) => {
        _price -= discount.credits;
    });
    if(_price < 0) _price = 0;
    return { total: _price };
}

/**
 * Passed subCategoryDict, product and products, it calculates the price of the product in the cart based 
 * on the quantity of the similar products in the cart and the price of the product
 * @param {*} param0 
 * @returns 
 */
export const calculatePriceInCart = ({ subCategoryDict, product, products }) => {
    const originalProduct = subCategoryDict[product.subCategoryId].subProductDict[product.productId];
    const properties = getAllUrlParams(product.productUrl)?.properties;
    const modifiers = getModifierByProperties(properties?.split(";") || [], originalProduct)
    let imagesInCart = getQuantityOfProductInCart({ cart: products, productId: product.productId, processingType: product.processingType });
    let { originalPrice, kanguryPrice } = calculatePrice({ prices: product.prices, imagesList: product.imagesList, imagesInCart, processingType: product.processingType, countInCart: product.countInCart, kanguryPrices: product.kanguryPrices });
    if (modifiers) {
        kanguryPrice = kanguryPrice + priceModifiers(originalPrice, modifiers);
    }
    return parseFloat(kanguryPrice.toFixed(2));
}

/**
 * Passed the total price of the products in the cart, the country and the couriers, it 
 * returns the courier that can be used for the order looking for in the couriers list
 * @param {*} param0 
 * @returns 
 */
export const findCourier = ({ totalPrice, country, couriers, state = null }) => {
    let c = null;
    if (country && couriers) {
        couriers.every((item) => {
            item.shippingPrices.every((price) => {
                const minPrice = price.minOrderPrice;
                const maxPrice = price.maxOrderPrice;
                if ((totalPrice >= minPrice || !minPrice) && (totalPrice <= maxPrice || !maxPrice)) {
                    if (price.areas.some(area => area.country === country && (!state || !area.statesExcluded || area.statesExcluded?.includes(state) === false))) {
                        c = {
                            id: item.id,
                            name: item.name,
                            price: price.price,
                            standardDeliveryDays: item.standardDeliveryDays,
                        };
                        return false;
                    }
                }
                return true;
            });
            if (c)
                return false;
            return true;
        });
    }
    return c;
}

/**
 * Function to create item to add to the cart and save the in localDB
 * @param {*} product product to add to the cart
 * @param {*} general general information about catalog I need to save for better performance
 * @returns 
 */
export const createCartItem = (product, general) => {
    const { categoryId, subCategoryId, properties } = getAllUrlParams();
    // find image with type 3 in product images
    const image = product.images.find((image) => image.type === 3);
    let item = {
        countInCart: 1,
        general,
        title: product.name,
        code: product.code,
        productId: product.id,
        categoryId: parseInt(categoryId),
        subCategoryId: parseInt(subCategoryId),
        properties,
        prices: product?.prices,
        kanguryPrices: product?.kanguryPrices,
        processingType: product?.processingType,
        image: image?.url || product.images[0].url,
        templateId: product.templates[0].id,
        id: Date.now(),
    };
    return item;
};

/**
 * Translate the code coming from the server to a readable status for user
 * @param {*} code number  coming from the server
 * @returns 
 */
export const traslateCodeToStatus = (code) => {
    switch (code) {
        case 0:
            return "Non attivo";
        case 1:
            return "Attivo";
        case 10:
            return "In attesa di convalida";
        case 98:
            return "Annullato";
        case 100:
            return "In Attesa di Pagamento";
        case 101:
            return "Pagamento Ricevuto";
        case 102:
            return "In Elaborazione";
        case 103:
            return "In Produzione";
        case 104:
            return "Stampato";
        case 108:
            return "Evaso";
        case 110:
            return "Spedito";
        case 111:
            return "Consegnato";
        default:
            return "";
    }
}
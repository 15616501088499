import React, { useState, useEffect } from 'react';
import Zoomist from 'zoomist';
import './style.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export function ImgWithPlaceholder(props) {
    const [currentImage, setCurrentImage] = useState(props.placeholder);

    useEffect(() => {
        const clientBrowserIsSafary = navigator.userAgent.includes('Safari') && !navigator.userAgent.includes('Chrome');
        if (props.type && props.type.includes('heic') && !clientBrowserIsSafary) {
            fetch(props.src)
                .then(response => response.blob())
                .then(blob => window.heic2any({ blob, toType: 'image/jpeg' }))
                .then(conversionResult => {
                    const url = URL.createObjectURL(conversionResult);
                    setCurrentImage(url);
                })
                .catch(error => {
                    console.error('Error converting HEIC image:', error);
                    setCurrentImage(props.placeholder);
                });
        } else {
            setCurrentImage(props.src);
        }
    }, [props.src, props.placeholder, props.type]);

    useEffect(() => {
        if (!props.enableZoom || currentImage === props.placeholder || props.itemId === undefined) return;
        new Zoomist(`.${props.itemId}`, {
            // Optional parameters
            maxScale: 4,
            bounds: true,
            // if you need slider
            slider: false,
            // if you need zoomer
            zoomer: false,
            draggable: true,
        });
    }, [currentImage, props.enableZoom, props.itemId, props.placeholder]);

    if (props.enableZoom && currentImage !== props.placeholder) {
        return <div className={`zoomist-container w-100 h-100 ${props.itemId}`}>
            <div className="zoomist-wrapper w-100 h-100 ">
                <div className="zoomist-image w-100 h-100 d-flex justify-content-center align-items-center">
                    <img src={currentImage} alt={props.alt} className='w-100 h-auto mh-100 object-fit-contain' />
                </div>
            </div>
        </div>;
    }

    if (props.enableZoom) {
        return <img src={currentImage} alt={props.alt} className={props.className} />;
    }

    const favorite = props.favorite ?
        <FontAwesomeIcon icon="fa-solid fa-star"
            className="position-absolute top-0 end-0 text-primary mt-2 me-2"
            size='1x' />
        : null;

    if (props.isVideo) {
        return (
            <div className='position-relative'>
                <img {...props} src={currentImage} alt={props.alt} lazy="loading" />
                <FontAwesomeIcon icon="fa-solid fa-circle-play"
                    className="position-absolute top-50 start-50 translate-middle bg-primary text-tertiary rounded-circle cursor-pointer z-1000 pe-none" size='2x'
                />
                {favorite}
            </div>
        );
    }

    if (favorite) {
        return (
            <div className='position-relative'>
                <img {...props} src={currentImage} alt={props.alt} lazy="loading" />
                {favorite}
            </div>
        );
    }

    return <img {...props} src={currentImage} alt={props.alt} lazy="loading" />
}
import React, { useState } from 'react';
import { Page } from '../../../components';
import { adminService } from '../../../store/services';
import { Button } from '../../../ui';

export function AdminCreateAlbum() {
    const [email, setEmail] = useState('');
    const [maxSize, setMaxSize] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');

    const handleCreateAlbum = async () => {
        setMessage('');
        setError('');
        try {
            const response = await adminService.createAlbum({ email, maxSize });
            if (response.id) {
                setMessage('Album creato con successo!');
            } else {
                setError('Errore durante la creazione dell\'album.');
            }
        } catch (err) {
            setError('Errore durante la creazione dell\'album.');
        }
    };

    return (
        <Page visibleBottomNavigation={false} title={"Pannello Amministratore"}>
            <div className='p-2'>
                <div className='mb-3'>
                    <label>Email</label>
                    <input
                        type="email"
                        className="form-control"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </div>
                <div className='mb-3'>
                    <label>Dimensione Massima dell'Album (byte)</label>
                    <input
                        type="number"
                        className="form-control"
                        value={maxSize}
                        onChange={(e) => setMaxSize(e.target.value)}
                        placeholder='(valore di default: 500000000)'
                    />
                </div>
                <Button onClick={handleCreateAlbum} otherClass={"btn-primary mt-3"}>
                    Crea Album
                </Button>
                {message && <div className="alert alert-success mt-3">{message}</div>}
                {error && <div className="alert alert-danger mt-3">{error}</div>}
            </div>
        </Page>
    );
}
import { common } from "./common.service"

export const adminService = {
    sendDiscounts,
    createAlbum,
    unlockOrder
}

async function sendDiscounts(data) {
    let url = `admin/send-discounts-code`;
    return common.fetchAPI({
        url,
        method: "POST",
        token: true,
        data,
    });
}

async function createAlbum(data) {
    let url = `admin/create-album`;
    return common.fetchAPI({
        url,
        method: "POST",
        token: true,
        data,
    });
}

async function unlockOrder(id) {
    const url = `admin/unlock-order?orderId=${id}`;
    return common.fetchAPI({
        url,
        method: "POST",
        token: true,
    });
}
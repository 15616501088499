import React from 'react';
import "swiper/css";
import { Button } from '../../ui'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatMemory } from '../../libs';


export function MediaSettings({ visible, handleClose, handleDelete, handleSetCover, handleToggleFavorite, media, isOwner }) {

    const height = isOwner ? 'h-30' : 'h-20';

    return (
        <div className={`position-fixed bottom-0 start-0 w-100 h-100 bg-dark d-flex align-items-end z-1000 bg-opacity-50 ${visible ? 'visibility-visible' : 'visibility-invisible pe-none'}`}
            onClick={handleClose}>
            <div className={`bg-secondary bg-opacity-50 w-100 p-2 transition-base gap-1 rounded-top d-flex justify-content-start align-items-center flex-column pb-4 ${visible ? height : ' h-0'}`} onClick={(e) => e.stopPropagation()}>
                <FontAwesomeIcon icon="fa-regular fa-dash" className="text-white" />
                <h5 className="text-white mb-0">
                    Dimensione: {formatMemory(media?.size)}
                </h5>
                <h5 className="text-white">
                    Tipo file: {media?.type}
                </h5>
                <div className="d-flex gap-2 flex-column w-100 h-100 justify-content-space-around">
                    {media?.favorite
                        ? <Button onClick={handleToggleFavorite}>
                            Rimuovi dai preferiti
                        </Button>
                        : <Button onClick={handleToggleFavorite}>
                            <FontAwesomeIcon icon="fa-regular fa-star" className={'me-2'} />
                            Inserisci tra i preferiti
                            <FontAwesomeIcon icon="fa-regular fa-star" className={'ms-2'} />
                        </Button>
                    }
                    {isOwner && <Button onClick={handleSetCover} otherClass={"btn-secondary"}>
                        Imposta come foto copertina
                    </Button>}
                    {/* <Button otherClass={"btn-secondary text-white"} onClick={handleButton}>
                    <FontAwesomeIcon icon="fa-regular fa-circle-info" className=" me-2 fs-3" />
                    Dettagli foto
                </Button> 
                <Button otherClass={"btn-secondary text-white"} onClick={handleButton}>
                    <FontAwesomeIcon icon="fa-regular fa-download" className="fs-3 me-2" />
                    Scarica originale
                </Button>
                <Button otherClass={"btn-secondary text-white"} onClick={handleButton}>
                    <FontAwesomeIcon icon="fa-regular fa-share-nodes" className=" me-2 fs-3" />
                    Condividi
                </Button> */}
                    {isOwner && <Button otherClass={"btn-danger text-white"} onClick={handleDelete}>
                        <FontAwesomeIcon icon="fa-regular fa-trash-can" className=" me-2 fs-3" />
                        Elimina
                    </Button>}
                </div>
            </div>
        </div>
    );
}
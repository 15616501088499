import { common } from "./common.service"

export const favoriteService = {
    toggle
}

/**
 * Get list of all files of an album
 * @param {*} id 
 * @param {*} fingerprint 
 * @returns 
 */
async function toggle(id, isFavorite) {
    let url = `favorite`;
    const data = {
        file_ids: [id]
    }
    if (isFavorite) {
        return common.fetchAPI({ url, method: "DELETE", data, token: true });
    } else {
        return common.fetchAPI({ url, method: "POST", data, token: true });
    }
}
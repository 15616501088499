import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Page, DeliveryDateCategory, BottomButton } from "../../components";
import { createCartItem, getAllUrlParams, goToPhotobook, isContainerMd } from "../../libs";
import { cartActions, productDataActions } from "../../store/actions";
import { CarouselDetails, Accordion } from "../../ui";

const handleBackPage = () => {
  window.history.back();
};

export function Details() {
  const dispatch = useDispatch();
  const { subCategoryId, categoryId } = getAllUrlParams();
  const { subCategoryDict, general, categoryDict } = useSelector((state) => state.mainData);
  const navigate = useNavigate();
  const [isContainerMdValue, setIsContainerMdValue] = React.useState(isContainerMd());

  // add useeffect with listener on resize
  React.useEffect(() => {
    const handleResize = () => {
      setIsContainerMdValue(isContainerMd());
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  React.useEffect(() => {
    dispatch(productDataActions.unlockClean());
    dispatch(productDataActions.clean());
  }, [dispatch]);

  if (!subCategoryDict || !categoryDict)
    return <Page loading={true} />

  const nameCategory = categoryDict[categoryId]?.name;

  const category = subCategoryDict[subCategoryId];
  // check if the category has only one product with processingType = 5, so I need to
  // add the product to the cart and redirect to the cart page, not go to selection page
  const addToCart = Object.keys(category.subProductDict).length === 1 &&
    category.subProductDict[Object.keys(category.subProductDict)[0]]
      .processingType === 5;

  const handleButtonClick = () => {
    /**
     * When user click the button we can have 3 cases:
     * 1. In the category we have different selections, so we redirect to the selection page
     * 2. In the category we have only one product, so we redirect to the product page or property page if the product has properties not editable in the editor
     * 3. In the category we have only one product with processingType = 5, we add the product to the cart and redirect to the cart page
     */
    if (category.externalEditUrl) {
      goToPhotobook({ category, categoryId, subCategoryId, navigate });
      return;
    }

    if (Object.values(category.selectionDict).length > 0)
      navigate(
        `/selection?categoryId=${categoryId}&subCategoryId=${subCategoryId}`
      );
    else {
      if (addToCart) {
        // Add to cart (example: "Busta Regalo")
        dispatch(
          cartActions.add(
            createCartItem(
              category.subProductDict[Object.keys(category.subProductDict)[0]],
              general
            )
          )
        );
        navigate(`/cart`);
      } else {
        const product =
          category.subProductDict[Object.keys(category.subProductDict)[0]];
        const hasPropertyNotEditableInEditor = product.properties?.some(
          (p) => p.editInEditor === false
        );
        if (hasPropertyNotEditableInEditor)
          navigate(
            `/property?categoryId=${categoryId}&subCategoryId=${subCategoryId}&productId=${product.id}`
          );
        else
          navigate(
            `/warn-exit-product?categoryId=${categoryId}&subCategoryId=${subCategoryId}&productId=${product.id}`
          );
      }
    }
  };
  const carouselPresent = subCategoryDict[subCategoryId].images.length > 0;

  const handleGoHome = () => {
    navigate("/");
  };

  return (
    <Page
      customBottomSection={
        <BottomButton onClick={handleButtonClick}
          disabled={subCategoryDict[subCategoryId].price.price === -1}
          content={(
            <React.Fragment>
              <FontAwesomeIcon
                icon="fa-regular fa-cart-plus" className="me-2" />
              {addToCart ? "Aggiungi al carrello" : "Crea ora"}
            </React.Fragment>
          )} />
      }
    >
      <div className={`h-100 row ${isContainerMdValue ? 'p-3' : ''}`}>
        {subCategoryDict && (
          <React.Fragment>
            {carouselPresent && <div className="col-12 col-md-6 d-flex align-items-center">
              <div className="position-relative w-100">
                <CarouselDetails images={subCategoryDict[subCategoryId].images} />
                <button
                  className="position-fixed btn btn-light top-0 start-0 ms-3 mt-3 rounded-5 p-2 d-flex align-items-center  z-1 "
                  onClick={handleBackPage}
                >
                  <FontAwesomeIcon
                    icon="fa-regular fa-arrow-left"
                    className="fs-4"
                  />
                </button>
                <button
                  className="position-fixed btn btn-light top-0 end-0 me-3 mt-3 rounded-5 p-2 d-flex align-items-center  z-1 "
                  onClick={handleGoHome}
                >
                  <FontAwesomeIcon
                    icon="fa-regular fa-house"
                    className="fs-4"
                  />
                </button>
              </div>
            </div>}
            <div className="col-12 col-md-6 d-flex align-items-center">
              <div className={`bg-white rounded-5 rounded-bottom z-1 p-4 position-relative flex-grow-1 ${isContainerMdValue ? 'border' : 'mt-n5 shadow-solo-top border-top'} `}>
                <div className="p-3">
                  <div className="fw-bold text-primary">{nameCategory}</div>
                  <h1 className="fw-bold">{subCategoryDict[subCategoryId].name}</h1>
                  {subCategoryDict[subCategoryId].price.price !== -1 ?
                    <div className="d-flex fw-bold">
                      <h5 className="me-2">da</h5>
                      {subCategoryDict[subCategoryId].price.originalPrice ? (
                        <React.Fragment>
                          <h5 className="text-primary me-2">
                            {subCategoryDict[subCategoryId].price.price.replace(".", ",") + " €"}
                          </h5>
                          <h5 className="text-decoration-line-through fw-light fst-italic">
                            {subCategoryDict[subCategoryId].price.originalPrice.replace(".", ",") + " €"}
                          </h5>
                        </React.Fragment>
                      ) : (
                        <h5>{subCategoryDict[subCategoryId].price.price.replace(".", ",") + " €"}</h5>
                      )}
                    </div>
                    : <h2 className="text-danger me-2 fs-5 fw-bold">
                      Il prodotto non è al momento disponibile
                    </h2>}

                  <p>{subCategoryDict[subCategoryId].description}</p>
                  <DeliveryDateCategory subCategoryId={subCategoryId} />
                </div>
                {subCategoryDict[subCategoryId]?.faqs.length > 0 &&
                  <Accordion items={subCategoryDict[subCategoryId]?.faqs} />}
              </div>
            </div>
          </React.Fragment>
        )}
      </div>
    </Page>
  );
}

import React from 'react';
import { Page } from '../../components';
import { CardDiscount } from '../../components/card-discount';
import { discountService } from '../../store/services';

export function DiscountCodeList() {
    const [discountCodesList, setDiscountCodesList] = React.useState({});

    React.useEffect(() => {
        discountService.getAll()
            .then(data => {
                setDiscountCodesList(data);
            })
            .catch(err => {
                console.error(err);
            });
    }, [])

    return (
        <Page visibleBottomNavigation={false}
            title={"Codici Sconto Disponibili"}>
            {discountCodesList && discountCodesList.map
                && discountCodesList.map((discount, index) => {
                    return (
                        <CardDiscount
                            key={index}
                            title={discount.description}
                            code={discount.code}
                            shareAndCopy={true}
                            dateLimit={discount.expiresAt}
                            minItemTotal={discount.minItemTotal}
                        />
                    )
                })}
        </Page>
    ); 
}
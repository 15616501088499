import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { BottomButton, Page } from '../../components';
import { applyDiscounts, priceModifiers, calculateDimPercentage, calculatePrice, findCourier, getAllUrlParams, getModifierByProperties, getQuantityOfProductInCart } from '../../libs';
import { cartActions, userActions } from '../../store/actions';
import { orderService } from '../../store/services';
import { ProgressbarCircle, Button, FixedBottom } from '../../ui';

const ButtonType = {
    LOADING: 'LOADING',
    ERROR: 'ERROR',
    SUCCESS: 'SUCCESS',
}

export function UploadOrder() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { products, discount } = useSelector(state => state.cartData);
    const { couriers, general, subCategoryDict, courierKanguryPrice } = useSelector(state => state.mainData);
    const { shippingAddresses, billingAddresses } = useSelector((state) => state.userData);
    const defaultShippingAddress = shippingAddresses?.length > 0 ? shippingAddresses.find(item => item.isDefault) : null;
    const defaultBillingAddress = billingAddresses?.length > 0 ? billingAddresses.find(item => item.isDefault) : null;
    const [isOrderSent, setIsOrderSent] = React.useState(false); // When true miss only upload images
    const goToPaymentRef = React.useRef(false); // When true I can go to payment page
    const { askBill } = getAllUrlParams(); // if true I must use billing address
    // I calculate price while I set order json to not repeat the same loop 2 times
    const totalPriceRef = React.useRef(0);
    const totalOriginalPriceRef = React.useRef(0);
    const totalPriceKanguryRef = React.useRef(0);
    const totalOriginalPriceKanguryRef = React.useRef(0);
    // When all images are uploaded I can show success button
    const [imagesUploaded, setImagesUploaded] = React.useState(0);
    // Bottom button change type when order is sent or go to error page
    const [typeOfButton, setTypeOfButton] = React.useState(ButtonType.LOADING);
    const uploadImagesBlocked = React.useRef(false); // needs because typeOfButton inside upload images is async 
    // I use this to save the id of the order created (not useState because I don't want to rerender)
    const idOrderCreated = React.useRef(null);
    const isOrderSenting = React.useRef(false); // needs because typeOfButton inside upload images is async
    const [goToOrders, setGoToOrders] = React.useState(false);

    // Get addresses from localDB
    React.useEffect(() => {
        dispatch(userActions.getShippingAddresses());
        dispatch(userActions.getBillingAddresses());
    }, [dispatch]);

    /**
     * When order is sent and all images are uploaded I can remove all products from cart
     * and show success button
     */
    React.useEffect(() => {
        if (isOrderSent) {
            dispatch(cartActions.removeAll());
            setTypeOfButton(ButtonType.SUCCESS);
        }
    }, [dispatch, imagesUploaded, isOrderSent]);

    /**
     * For each imageList in the product set a page equal to the one send by server
     * but updated with the image uploaded and modified by the user
     */
    const setPage = React.useCallback((product, indexProduct, image, index, name) => {
        const _template = product.template.length > 1 ?
            { ...product.template[index] } :
            { ...product.template[0] };
        const template = JSON.parse(JSON.stringify(_template));
        template.pageNumber = template.pageNumber || 0;
        template.name = template.name || `${name}`;
        let indexImage = template.designObjects.findIndex(object => object.image.type === 0);
        if (product.processingType === 1 && image.imageHeight > image.imageWidth) {
            // Rotate template if image is vertical in processingType 1 (ex. Classic Photo)
            const tmp = template.height;
            template.height = template.width;
            template.width = tmp;
        }
        const { width, height, xIncr, yIncr } = calculateDimPercentage(image, template);
        template.designObjects[indexImage] = {
            ...template.designObjects[indexImage],
            imageWidth: width * (image.editorProps?.zoom || 1),
            imageHeight: height * (image.editorProps?.zoom || 1),
            imageX: 50 + (image.editorProps?.x || 0) * (image.editorProps?.zoom || 1) * xIncr,
            imageY: 50 + (image.editorProps?.y || 0) * (image.editorProps?.zoom || 1) * yIncr,
            imageRotation: (image.editorProps?.rotate || 0),
            image: {
                type: 0,
                src: image.imageName,
                width: image.imageWidth,
                height: image.imageHeight,
                id: image.id,
                fingerPrint: image.fingerPrint,
                uploaded: true,
            },

        }
        return template;
    }, []);

    /**
     * For each product add all pages, one page for each image in product.imagesList
     */
    const addPages = React.useCallback((product, indexProduct) => {
        if (!product.imagesList || product.imagesList.length === 0) return null;
        let pages = [];
        let indexName = 0;
        product.imagesList.forEach((image, index) => {
            for (let i = 0; i < image.quantity; i++) {
                pages.push(setPage(product, indexProduct, image, index, indexName))
                indexName++;
            }
        });
        return pages;
    }, [setPage]);

    /**
     * Calculate price of the product passed
     */
    const prices = React.useCallback((product) => {
        // Get quantity of images in cart of the same product
        let imagesInCart = getQuantityOfProductInCart({ cart: products, productId: parseInt(product.productId), processingType: product.processingType });
        // Calculate price (maybe modified by discount) and original price        
        let { price, originalPrice, kanguryPrice, kanguryOriginalPrice } = calculatePrice({ prices: product.prices, imagesList: product.imagesList, imagesInCart, countInCart: product.countInCart, processingType: product.processingType, kanguryPrices: product.kanguryPrices });
        // Get price modifiers by properties selected and apply them
        const originalProduct = subCategoryDict[product.subCategoryId].subProductDict[product.productId];
        const modifiers = getModifierByProperties(product.properties?.split(";") || [], originalProduct);
        if (modifiers) {
            kanguryPrice = kanguryPrice + priceModifiers(originalPrice, modifiers);
            kanguryOriginalPrice = kanguryOriginalPrice + priceModifiers(originalPrice, modifiers);
            price = price + priceModifiers(originalPrice, modifiers);
            originalPrice = originalPrice + priceModifiers(originalPrice, modifiers);
        }
        return {
            price: parseFloat(price.toFixed(2)),
            originalPrice: parseFloat(originalPrice.toFixed(2)),
            kanguryPrice: parseFloat(kanguryPrice.toFixed(2)),
            kanguryOriginalPrice: parseFloat(kanguryOriginalPrice.toFixed(2)),
        };
    }, [products, subCategoryDict]);

    /**
     * Create json order to send to server
     */
    const addItems = React.useCallback(() => {
        const items = [];
        const kanguryItems = [];
        products.forEach((product, index) => {
            const { price, originalPrice } = prices(product);
            // Add price and original price to total price in order not to do an other loop
            if (product.processingType === 100) {
                totalPriceKanguryRef.current += price;
                totalOriginalPriceKanguryRef.current += originalPrice;
                const unitPrice = parseFloat((price / product.countInCart).toFixed(2));
                const originalUnitPrice = parseFloat((originalPrice / product.countInCart).toFixed(2));
                kanguryItems.push({
                    id: index,
                    iD_Product: parseInt(product.productId),
                    quantity: product.countInCart,
                    unitPrice,
                    originalUnitPrice,
                    id_PropertyValues: product.properties ? product.properties.split(';').map(item => parseInt(item)) : [],
                    id_Template: product.templateId || null,
                    pages: addPages(product, index),
                });
            }
            else {
                totalPriceRef.current += price;
                totalOriginalPriceRef.current += originalPrice;
                if (product.processingType !== 1) {
                    // If processingType is not 1 add product normally
                    // cast unitPrice to float with 2 decimals
                    const unitPrice = parseFloat((price / product.countInCart).toFixed(2));
                    const originalUnitPrice = parseFloat((originalPrice / product.countInCart).toFixed(2));
                    if (product.processingType === 6) {
                        items.push({
                            id: product.id,
                            iD_Product: parseInt(product.productId),
                            quantity: product.countInCart,
                            unitPrice,
                            id_PropertyValues: product.properties ? product.properties.split(';').map(item => parseInt(item)) : [],
                            id_Template: product.templateId || null,
                            fingerPrint: product.fingerPrint
                        });
                    } else {
                        items.push({
                            id: index,
                            iD_Product: parseInt(product.productId),
                            quantity: product.countInCart,
                            unitPrice,
                            originalUnitPrice,
                            id_PropertyValues: product.properties ? product.properties.split(';').map(item => parseInt(item)) : [],
                            id_Template: product.templateId || null,
                            pages: addPages(product, index),
                        });
                    }

                }
                else {
                    // If processingType is 1 add an item for each image in product.imagesList
                    product.imagesList.forEach((image, indexImage) => {
                        items.push({
                            id: index,
                            iD_Product: parseInt(product.productId),
                            quantity: image.quantity,
                            unitPrice: parseFloat((price / product.totalImages).toFixed(2)),
                            originalUnitPrice: parseFloat((originalPrice / product.totalImages).toFixed(2)),
                            id_PropertyValues: product.properties ? product.properties.split(';').map(item => parseInt(item)) : [],
                            id_Template: product.templateId || null,
                            pages: [setPage(product, index, image, indexImage)]
                        });
                    });
                }
            }
        });
        return { items, kanguryItems };
    }, [addPages, prices, products, setPage]);

    const addKangoryData = React.useCallback((products) => {
        let totalKanguryPrice = 0;
        let totalKanguryOriginalPrice = 0;
        products.forEach((product, index) => {
            const { kanguryPrice, kanguryOriginalPrice } = prices(product);
            // Add price and original price to total price in order not to do an other loop
            totalKanguryOriginalPrice += kanguryOriginalPrice;
            totalKanguryPrice += kanguryPrice;
        });
        const { total } = applyDiscounts(discount, totalKanguryOriginalPrice)
        const discountCode = discount && discount.length > 0 ? discount[0].code : null;
        if (discountCode) {
            return {
                itemsTotal: totalKanguryPrice.toFixed(2).toString(),
                itemsDiscount: (totalKanguryOriginalPrice - total).toFixed(2).toString(),
                shippingCost: courierKanguryPrice.toFixed(2).toString(),
                discountCode
            };
        } else {
            return {
                itemsTotal: totalKanguryPrice.toFixed(2).toString(),
                itemsDiscount: (totalKanguryOriginalPrice - total).toFixed(2).toString(),
                shippingCost: courierKanguryPrice.toFixed(2).toString(),
            };
        }

    }, [courierKanguryPrice, discount, prices]);

    /**
   * The following functions are used to handle the upload of a single image,
   * manages also to retry the upload if it fails up to 3 times
   */
    const postOrder = React.useCallback(() => {
        if (products && products.length > 0 && general && defaultShippingAddress && (askBill === "false" || (askBill === "true" && defaultBillingAddress)) && !isOrderSent && !isOrderSenting.current) {
            isOrderSenting.current = true;
            totalPriceRef.current = 0;
            totalOriginalPriceRef.current = 0;
            totalPriceKanguryRef.current = 0;
            totalOriginalPriceKanguryRef.current = 0;
            uploadImagesBlocked.current = false;
            const data = {
                application: general.application,
                itemsTotal: totalPriceRef.current,
                itemsDiscount: totalOriginalPriceRef.current - totalPriceRef.current,
                shippingAddress: {
                    recipient: defaultShippingAddress.recipient,
                    address: defaultShippingAddress.address,
                    zipCode: defaultShippingAddress.zipCode,
                    city: defaultShippingAddress.city,
                    state: defaultShippingAddress.state,
                    country: defaultShippingAddress.country,
                    note: defaultShippingAddress.courierNotes || null,
                    phone: defaultShippingAddress.phoneNumber
                }
            }
            if (askBill === "true") {
                data.billingAddress = {
                    name: defaultBillingAddress.name,
                    address: defaultBillingAddress.address,
                    zipCode: defaultBillingAddress.zipCode,
                    city: defaultBillingAddress.city,
                    state: defaultBillingAddress.state,
                    country: defaultBillingAddress.country,
                    fiscalCode: defaultBillingAddress.fiscalCode,
                    vatCountry: defaultBillingAddress.vatCountry,
                    vatNumber: defaultBillingAddress.vatNumber || null,
                    pec: defaultBillingAddress.pec || null,
                    recipientCode: defaultBillingAddress.recipientCode || null,
                }
            }
            const { items, kanguryItems } = addItems();
            data.items = items;
            data.discounts = [];
            const country = defaultShippingAddress?.country;
            const courier = findCourier({ couriers, country, totalPrice: totalPriceRef.current, });

            data.itemsTotal = parseFloat(totalPriceRef.current.toFixed(2));
            data.itemsDiscount = 0;
            data.shippingCost = parseFloat(courier.price.toFixed(2));
            data.shippingDiscount = 0;
            data.id_Courier = courier?.id;

            const dataCompleted = {
                dataDP: data,
                dataK: kanguryItems,
                ...addKangoryData(products)
            }

            orderService.post(dataCompleted).then(response => {
                if (response.status === 100) {
                    idOrderCreated.current = response.id;
                    goToPaymentRef.current = true; // Add check if in future we have order that not need payment (for example for discount code)
                    setIsOrderSent(true);
                }
                else if (response.status === 101) {
                    setIsOrderSent(true);
                    setGoToOrders(true);
                }
                else if (response.status !== undefined)
                    setTypeOfButton(ButtonType.ERROR);
            }).catch(error => {
                window.logMessage({ error })
                setTypeOfButton(ButtonType.ERROR);
            }).finally(() => {
                isOrderSenting.current = false;
            });
        }
    }, [addItems, addKangoryData, askBill, couriers, defaultBillingAddress, defaultShippingAddress, general, isOrderSent, products]);

    /**
     * The following functions are used to handle the onClick event of the bottom button
     */
    const handleRetry = React.useCallback(() => {
        setIsOrderSent(false);
        setTypeOfButton(ButtonType.LOADING);
        setImagesUploaded(0);
        postOrder();
    }, [postOrder]);
    const handleGoToPayment = React.useCallback(() => {
        navigate('/orders')
        if (goToPaymentRef.current)
            navigate(`/pay?orderId=${idOrderCreated.current}`);
    }, [navigate]);

    const goBack = React.useCallback(() => {
        window.history.back();
    }, []);
    /**
     * Here starts the main function of the component, here the json order is created and sent to the server,
     * when the server responds the images are uploaded using the id of the images returned by the server
     */
    React.useEffect(() => {
        postOrder();
    }, [postOrder]);

    /**
     * render the correct bottom button
     */
    const renderBottomSection = React.useMemo(() => {
        switch (typeOfButton) {
            case ButtonType.ERROR:
                return (
                    <FixedBottom>
                        <div className='pt-2 pb-2 ps-3 pe-3 w-100 d-flex gap-1'>
                            <Button onClick={handleRetry} otherClass={"p-1 btn-danger text-white"}>
                                <span>Prova di nuovo</span>
                            </Button>
                            <Button onClick={goBack} otherClass={"p-1 btn-secondary"}>
                                <span>Torna indietro</span>
                            </Button>
                        </div>
                    </FixedBottom>
                )
            case ButtonType.SUCCESS:
                return <BottomButton otherClass={"p-1"}
                    onClick={handleGoToPayment}
                    content={goToPaymentRef.current ? <span>Prosegui con il Pagamento</span> : <span>Vai al Riepilogo Ordini</span>}>
                </BottomButton>
            default:
                return <></>
        }

    }, [goBack, handleGoToPayment, handleRetry, typeOfButton]);

    /**
     * Render the correct text based on order status
     */
    const textDisplayed = React.useMemo(() => {
        if (typeOfButton === ButtonType.LOADING && !isOrderSent) return "Invio Ordine...";
        if (typeOfButton === ButtonType.LOADING && isOrderSent) return "Caricamento Immagini...";
        if (typeOfButton === ButtonType.ERROR) return "Errore nell'invio dell'ordine";
        if (typeOfButton === ButtonType.SUCCESS) return "Ordine inviato con successo";
        return "";
    }, [isOrderSent, typeOfButton]);

    /**
     * Render the correct percentage based on the order status and images uploaded
     */
    const percentage = React.useMemo(() => {
        if (!isOrderSent) return 0;
        return 100;
    }, [isOrderSent]);

    if (products.length === 0 && !isOrderSent) {
        navigate('/cart');
        return;
    }

    if (goToOrders) {
        dispatch(cartActions.removeAll());
        navigate('/orders');
        return;
    }

    return (
        <Page title="Caricamento Ordine"
            customBottomSection={renderBottomSection} simpleTitle={true}>
            <div className="container d-flex justify-content-center align-items-center flex-column w-100 h-100">
                {typeOfButton === ButtonType.LOADING && <ProgressbarCircle percentage={percentage} />}
                <h1 className="text-center mt-3">
                    {textDisplayed}
                </h1>
                {typeOfButton === ButtonType.LOADING && <h5 className='mt-4 text-danger pe-4 ps-4 text-center'>Non chiudere l'app e assicurarsi di avere la connessione disponibile</h5>}
            </div>
        </Page>
    );
}
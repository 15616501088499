import React, { useState } from 'react';

export const Permission = {
    ALL: 'all',
    READ: 'read',
    WRITE: 'write',
    OWNED: 'owned',
}

export function ModalFilters({
    filters,
    setFilters,
}) {
    const [name, setName] = useState('');
    const [dateFrom, setDateFrom] = useState('');
    const [dateTo, setDateTo] = useState('');
    const [permission, setPermission] = useState('all');

    const handleConfirm = () => {
        const newFilters = {
            name,
            dateFrom,
            dateTo,
            permission,
        };
        setFilters(newFilters);
    };

    const handleReset = () => {
        setName('');
        setDateFrom('');
        setDateTo('');
        setPermission(Permission.ALL);
        setFilters({
            name: '',
            dateFrom: '',
            dateTo: '',
            permission: Permission.ALL,
        });
    };

    return (
        <div
            className="modal fade z-1100"
            id={'filterModal'}
            tabIndex="-1"
            aria-labelledby="mainModalLabel"
        >
            <div className="modal-dialog top-25">
                <div className="modal-content">
                    <div className="modal-header text-center w-100">
                        <h5 className="modal-name fw-bold text-center w-100" id="mainModalLabel">
                            Filtri
                        </h5>
                    </div>
                    <div className="modal-body text-pre-line">
                        <div className="mb-3">
                            <label htmlFor="name" className="form-label">Titolo</label>
                            <input
                                type="text"
                                className="form-control"
                                id="name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="dateFrom" className="form-label">Data Da</label>
                            <input
                                type="date"
                                className="form-control"
                                id="dateFrom"
                                value={dateFrom}
                                onChange={(e) => setDateFrom(e.target.value)}
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="dateTo" className="form-label">Data A</label>
                            <input
                                type="date"
                                className="form-control"
                                id="dateTo"
                                value={dateTo}
                                onChange={(e) => setDateTo(e.target.value)}
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="permission" className="form-label">Album</label>
                            <select
                                className="form-select"
                                id="permission"
                                value={permission}
                                onChange={(e) => setPermission(e.target.value)}
                            >
                                <option value={Permission.ALL}>Tutti</option>
                                <option value={Permission.OWNED}>Propri</option>
                                <option value={Permission.READ}>Condivisi in lettura</option>
                                <option value={Permission.WRITE}>Condivisi per modifica</option>
                            </select>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <div className="row w-100">
                            <button
                                type="button"
                                className={`btn rounded-5 btn-primary col-6`}
                                onClick={handleConfirm}
                                data-bs-dismiss={"modal"}
                            >
                                Applica
                            </button>
                            <button
                                type="button"
                                className="btn rounded-5 btn-secondary col-6"
                                data-bs-dismiss="modal"
                                onClick={handleReset}
                            >
                                Resetta
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
import React, { useEffect } from "react";
import { Page } from '../../components/page';
import { Button, ToastDanger, ToastInfo } from "../../ui";
import { useNavigate } from "react-router-dom";
import { albumService, nfcService } from "../../store/services";
import { getAllUrlParams } from "../../libs";
import { Modals } from "./modals";

export function AlbumSettings() {
    const { id } = getAllUrlParams();
    const navigate = useNavigate();
    const [data, setData] = React.useState({});
    const [password, setPassword] = React.useState('');
    const [passwordError, setPasswordError] = React.useState(false);
    const [message, setMessage] = React.useState('');
    const [errorCode, setErrorCode] = React.useState(-1);
    const [showNotAccessError, setShowNotAccessError] = React.useState(false);
    const [nfcValue, setNfcValue] = React.useState('');
    const [nfcSupported, setNfcSupported] = React.useState(false);

    useEffect(() => {
        if ('NDEFReader' in window) {
            setNfcSupported(true);
            const ndef = new window.NDEFReader();
            ndef.scan().then(() => {
                ndef.onreading = (event) => {
                    const decoder = new TextDecoder();
                    for (const record of event.message.records) {
                        const recordValue = decoder.decode(record.data);
                        const id = recordValue.split('=')[1];
                        setNfcValue(id);
                        setMessage('NFC letto con successo:' + id);
                    }
                };
            }).catch((error) => {
                window.alert("Error starting NFC scan:" + error);
            });
        } else {
            setNfcSupported(false);
        }
    }, []);

    const handleAssociate = () => {
        if (nfcValue) {
            nfcService.put(nfcValue, id).then((data) => {
                // if no status no error
                if (!data.status) {
                    setMessage('Nuovo NFC associato con successo:' + nfcValue);
                    setErrorCode(-1);
                    setNfcValue('');
                    setData(prev => ({ ...prev, nfc: prev.nfc + 1 }));
                } else {
                    return data.json();
                }
            }).then((data) => {
                if (data?.errorCode) {
                    setErrorCode(data.errorCode);
                }
            });
        }
    }

    useEffect(() => {
        if (!data.name) {
            albumService.get(id).then((res) => {
                if (res.status === 403) {
                    setShowNotAccessError(true);
                    return;
                }
                setData(res);
            });
        }
    }, [data, id]);

    const confirmRemoveAlbum = () => {
        albumService.deleteAlbum(id, password).then((res) => {
            if (res.status === 204) {
                navigate('/album-list');
            } else {
                setPasswordError(true);
            }
        });
    }

    const confirmDisassociateAlbum = () => {
        nfcService.delete(nfcValue, id).then((res) => {
            if (res.message) {
                setMessage('Tag disassociato con successo');
                setData(prev => ({ ...prev, nfc: prev.nfc - 1 }));
                setNfcValue('');
                setErrorCode(-1);
            } else {
                return res.json();
            }
        }).then((data) => {
            if (data?.errorCode) {
                setErrorCode(data.errorCode);
            }
        });
    }

    if (showNotAccessError) {
        return <Page title="Errore">
            <div className="container mt-2">
                Non hai accesso alle impostazioni di questo album
            </div>
        </Page>
    }

    return (
        <Page title={data ? data.name : "-"}
            loading={data === undefined}
            visibleBottomNavigation={false}>

            <div className="container mt-2 d-flex flex-column align-items-center justify-content-between h-100 pb-4">
                <div>
                    <div className="w-100 text-center mt-4">
                        Smartphoto associate: {data?.nfc}
                    </div>

                    {nfcSupported ?
                        <>
                            <div className="w-100 text-center mt-2">
                                {data.nfc === 0 ?
                                    "Avvicina un nuovo tag nfc kangury che vuoi associare all'album" :
                                    "Avvicina un nuovo tag nfc kangury che vuoi associare all'album o uno già associato che vuoi disassociare"}
                            </div>
                            {data && nfcValue && data.nfc >= 1 ? <Button otherClass={"p-1 btn-secondary mt-1"} otherProps={{ 'data-bs-toggle': "modal", 'data-bs-target': "#disassociateAlbum" }}>
                                Disassocia Tag
                            </Button> : <></>}
                            {nfcValue && <Button otherClass={"p-1 btn-primary mt-3"} onClick={handleAssociate}>
                                Associa tag
                            </Button>}
                            {errorCode > 0 && <div className="w-100 text-center mt-2 text-danger">
                                {translateErrorCode(errorCode)}
                            </div>}
                        </> :
                        <div className="w-100 text-center mt-2 fw-bold ">
                            Lettore NFC non supportato dal dispositivo
                        </div>}

                    {message && <div className="w-100 text-center mt-2 fw-bold text-success">
                        {message}
                    </div>}
                </div>
                <div className="w-100 text-center mt-4">
                    <Button otherClass={"p-1 btn-danger mt-3"} otherProps={{ 'data-bs-toggle': "modal", 'data-bs-target': "#removeAlbum" }}>
                        Elimina Album
                    </Button>
                </div>
            </div>
            {
                data &&
                <Modals
                    confirmRemoveAlbum={confirmRemoveAlbum}
                    confirmDisassociateAlbum={confirmDisassociateAlbum}
                    password={password}
                    onChangePassword={(e) => setPassword(e.target.value)}
                    passwordError={passwordError}
                />
            }
        </Page>
    );
}

const translateErrorCode = (errorCode) => {
    switch (errorCode) {
        case 1:
            return 'Il tag NFC Kangury letto non è stato trovato nel nostro database, se il problema persiste contatta l\'assistenza';
        case 2:
            return 'Il tag Kangury letto è già associato ad un album';
        case 3:
            return 'il tag NFC Kangury letto non è associato a nessun album';
        default:
            return 'Errore, riprova più tardi, se il problema persiste contatta l\'assistenza';
    }
}
import React, { } from 'react';
import { Page } from '../../components';
import { Button } from '../../ui';
import { useNavigate } from 'react-router-dom';

export function PaymentCompleted() {
    const navigate = useNavigate();

    const goToAlbums = () => {
        navigate('/album-list');
    }

    const goToOrders = () => {
        navigate('/orders');
    }

    return (
        <Page visibleBottomNavigation={false} title={"Pagamento Completato"}>
            <div className='container text-center'>
                <h1 className=" mt-2">Adesso?</h1>
                <p>
                    Puoi iniziare a riempire gli album che sono stati creati, ne è stato creato uno per ogni foto che hai acquistato.
                </p>
                <p>
                    Appena riceverai a casa le foto stampate, potrai associare ciascuna di esse ad un album in modo da poter accedere facilmente alle tue foto preferite sfirando la foto con il tuo smartphone.
                </p>
                <Button onClick={goToAlbums}>Vai agli album</Button>
                <p className="mt-5">
                    Oppure puoi visualizzare lo stato dei tuoi ordini.
                </p>
                <Button otherClass="btn btn-secondary" onClick={goToOrders}>Vai agli ordini</Button>
            </div>
        </Page>
    );
}

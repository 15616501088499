import React from "react";
import { Modal } from "../../ui";

export const Modals = ({ confirmRemoveAlbum, password, onChangePassword, passwordError, confirmDisassociateAlbum }) => {
    return (
        <>
        <Modal
            title={"Sei sicuro di voler eliminare l'album?"}
            text={
                <>
                    <div>
                        <p>Questa azione è irreversibile e non potrai più usare la tua smartphoto con Kangury.</p>
                        <p>Inserisci la tua password per confermare l'eliminazione.</p>
                    </div>
                    <input type="password" className="form-control" placeholder="password" value={password}
                        onChange={onChangePassword} />
                    {passwordError && <div className="text-danger">Password errata</div>}
                </>
            }
            primaryText="Conferma eliminazione"
            secondaryText="Annulla"
            handleConfirm={confirmRemoveAlbum}
            id="removeAlbum"
            notDismiss={true}
        />
        <Modal
            title={"Vuoi disassociare l'album?"}
            text={
                <>
                    <div>
                        <p>Questa azione è irreversibile e non potrai più raggiungere l'album avvicinando lo smartphone a questo specifico tag.</p>
                        <p>Succesivamente potrai associare questo album di nuovo a questo stesso tag</p>
                    </div>
                </>
            }
            primaryText="Conferma disassociazione"
            secondaryText="Annulla"
            handleConfirm={confirmDisassociateAlbum}
            id="disassociateAlbum"
        />
        </>
    )
}
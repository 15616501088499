import React, { useEffect, useRef } from 'react';
import { Accordion } from "../../ui";
import { Page } from '../../components';
import { getAllUrlParams } from '../../libs';

export function HowItWorks() {
  const { scrollTo } = getAllUrlParams()
  const dubtsRef = useRef(null);

  useEffect(() => {
    if (scrollTo === 'dubts')
      dubtsRef.current.scrollIntoView({ behavior: 'smooth' });
  }, [scrollTo]);
  return (
    <Page title={'Spiegazione prodotto'}
      visibleBottomNavigation={true}>
      <div className="container py-3">

        {/* Titolo principale */}
        <div className="text-center fw-bold fs-3 mt-1 mb-1">Cos'è Kangury</div>
        <p className="text-center">
          Una foto? un cloud? un'app?🦸... <br />
          un portale verso i tuoi ricordi digitali.
        </p>
        {/* CARD 0: In sintesi*/}
        <div className="card rounded-4 overflow-hidden shadow mb-3 mt-1">
          <div className="card-body text-center">
            <div className="card-icon" style={{ fontSize: '2em' }}>📝</div>
            <h5 className="mt-2 fw-bold">Cos'è Kangury... in sintesi</h5>
            <p className="mx-3 mb-3">
              🖼️ Un magnifico <strong>quadro o foto stampata</strong> <br />
              💾 500 MB di <strong>spazio cloud</strong> per ciascun acquisto, tuoi per sempre<br />
              📱 Un'<strong>app</strong> con la quale esplorare, condividere o regalare tutti i tuoi ricordi più importanti<br />
              🪄 La <strong>magia</strong> di rivivere tutti i tuoi ricordi semplicemente sfiorando le tue foto<br />
            </p>
          </div>
        </div>
      </div>


      <div className="container py-3">
        {/* Titolo principale */}
        <div className="text-center fw-bold fs-3 mt-0 mb-2">L'esperienza utente:</div>
        <p className="text-center">
          Scopri in pochi semplici passaggi come mettere al sicuro i tuoi ricordi... Per sempre!
        </p>

        <div className="row ">
          <div className="col-12 col-md-6 d-flex align-items-stretch">
            {/* CARD 1: Personalizzazione del Prodotto */}
            <div className="card rounded-4 overflow-hidden shadow mb-3 mt-3 w-100">
              <div className="card-body text-center">
                <div className="card-icon" style={{ fontSize: '2em' }}>🖼️</div>
                <h5 className="mt-2 fw-bold">Personalizzazione del Prodotto</h5>
                <p className="mx-3 mb-3">
                  Scegli fra foto, tele o molto altro e carica la foto da stampare!<br />
                  <strong> Tutto made in Italy! </strong><span style={{ fontSize: '1.2em' }}>🇮🇹</span>
                </p>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-6 d-flex align-items-stretch">
            {/* CARD 2: Pagamento */}
            <div className="card rounded-4 overflow-hidden shadow mb-3 mt-3 w-100">
              <div className="card-body text-center">
                <div className="card-icon" style={{ fontSize: '2em' }}>💳</div>
                <h5 className="mt-2 fw-bold">Pagamento</h5>
                <p className="mx-3 mb-3">
                  Procedi all’acquisto in modo <strong>sicuro e veloce.</strong> <span style={{ fontSize: '1.2em' }}>🔒</span>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="row ">
          <div className="col-12 col-md-6 d-flex align-items-stretch">
            {/* CARD 3: Creazione Album Cloud */}
            <div className="card rounded-4 overflow-hidden shadow mb-3 mt-3">
              <div className="card-body text-center">
                <div className="card-icon" style={{ fontSize: '2em' }}>☁️</div>
                <h5 className="mt-2 fw-bold">Creazione Album Cloud</h5>
                <p className="mx-3 mb-3">
                  Appena concluso il pagamento, vedrai subito il tuo album
                  e potrai popolarlo con i tuoi ricordi più importanti! <span style={{ fontSize: '1.2em' }}>😊</span>
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 d-flex align-items-stretch">
            {/* CARD 4: Produzione & Spedizione */}
            <div className="card rounded-4 overflow-hidden shadow mb-3 mt-3">
              <div className="card-body text-center">
                <div className="card-icon" style={{ fontSize: '2em' }}>🚚</div>
                <h5 className="mt-2 fw-bold">Produzione & Spedizione</h5>
                <p className="mx-3 mb-3">
                  Il nostro laboratorio prende in carico la tua richiesta e
                  <strong> la spedizione è gratuita!</strong>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6 d-flex align-items-stretch">
            {/* CARD 5: Arrivo e Associazione del Prodotto */}
            <div className="card rounded-4 overflow-hidden shadow mb-3 mt-3">
              <div className="card-body text-center">
                <div className="card-icon" style={{ fontSize: '2em' }}>📦</div>
                <h5 className="mt-2 fw-bold">Arrivo e Associazione del Prodotto</h5>
                <p className="mx-3 mb-3">
                  Sfiora il prodotto con il tuo smartphone nell’angolo in basso a destra
                  e <strong>scegli l’album a cui associarlo</strong>! <span style={{ fontSize: '1.2em' }}>🔗</span>
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 d-flex align-items-stretch">

            {/* CARD 6: Goditi i tuoi ricordi */}
            <div className="card rounded-4 overflow-hidden shadow mb-3 mt-3">
              <div className="card-body text-center">
                <div className="card-icon" style={{ fontSize: '2em' }}>❤️</div>
                <h5 className="mt-2 fw-bold">Goditi i tuoi ricordi</h5>
                <p className="mx-3 mb-3">
                  <strong>Complimenti!</strong> Ora i tuoi ricordi sono al sicuro per sempre:
                  niente costi ricorrenti e saranno sempre a un tocco da te <span style={{ fontSize: '1.2em' }}>✨</span><br />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-100" ref={dubtsRef}>
        <h2 className="text-center fw-bold fs-3 mt-3 mb-2">Ancora dubbi?</h2>
        <Accordion items={helps} />
      </div>
      <div style={{ height: '40px' }}></div>
    </Page>
  )

}

const helps = [
  {
    id: 1,
    question: "Come posso abilitare l'NFC?",
    answer: "Per abilitare l'NFC sul tuo dispositivo, vai nelle impostazioni del tuo smartphone e cerca la voce NFC. Una volta trovata, attivala. Se non la trovi cerca su google inserendo il modello del tuo smartphone."
  },
  {
    id: 2,
    question: "Come posso leggere la smartphoto?",
    answer: "Per leggere la smartphoto, avvicina il dispositivo alla smartphoto acquistata su Kangury. Se il dispositivo non legge la smartphoto, assicurati che l'NFC sia attivo."
  },
  {
    id: 3,
    question: "Cosa posso fare se il dispositivo non legge la smartphoto?",
    answer: "Se il dispositivo non legge la smartphoto, assicurati che l'NFC sia attivo. Se il problema persiste, contatta il nostro servizio clienti."
  }
]
import React, { useEffect } from "react";
import { Page } from '../../components/page';
import { Button, AlbumList as AlbumListUI, CardSimple } from "../../ui";
import { useNavigate } from 'react-router-dom';
import { albumService, giftService } from "../../store/services";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SortAlbum } from "./sort";
import { GiftNotification, ShareNotification } from "../../components";

export function AlbumList() {
    const [albums, setAlbums] = React.useState(undefined)
    const originalAlbums = React.useRef(undefined);
    const navigate = useNavigate();
    const token = localStorage.getItem('token');
    const [gifts, setGifts] = React.useState(undefined);
    const isAdmin = localStorage.getItem('isAdmin') === 'true';
    const [activeAdmin, setActiveAdmin] = React.useState(false);

    const handleOpen = (id) => {
        navigate('/album?id=' + id);
    };

    const handleDeleteAdmin = (id) => {
        albumService.deleteAlbum(id, '').then((res) => {
            if (res.status === 204) {
                const newAlbums = albums.filter((album) => album.id !== id);
                setAlbums(newAlbums);
            }
        });
    }

    useEffect(() => {
        if (!albums) {
            albumService.getList().then((res) => {
                if (!res.status && res.length > 0) {
                    originalAlbums.current = res;
                    const putFirstSharedNotSeen =res.sort((a, b) => {
                        if (a.permission && !a.seen) {
                            return -1;
                        }
                        return 0;
                    });
                    setAlbums(putFirstSharedNotSeen);
                }
                else {
                    originalAlbums.current = [];
                    setAlbums([]);
                }
            });
        }
        if (!gifts) {
            giftService.get().then((res) => {
                setGifts(res);
            });
        }
    }, []);

    const goToShop = () => {
        navigate('/shop');
    }

    const goToLogin = () => {
        navigate('/login');
    }

    if (!token) {
        return <Page logoOnTop={true}>
            <div className="text-center w-100 h-100 d-flex flex-column justify-content-center align-items-center p-4">
                <h2>Devi effettuare il login per accedere a questa pagina</h2>
                <Button otherClass="btn-primary p-1 mt-3" onClick={goToLogin}>
                    <span className="text-tertiary  fs-5">
                        Vai al login
                    </span>
                </Button>
            </div>
        </Page>
    }

    return (
        <Page loading={!albums} logoOnTop={true}>
            <div className="p-3 h-100">
                {isAdmin && <Button otherClass="btn-primary p-1 mb-3" onClick={() => setActiveAdmin(!activeAdmin)}>
                    {activeAdmin ? 'Disattiva' : 'Attiva'} Modalità Admin
                </Button>}
                <SortAlbum setAlbums={setAlbums} albums={albums}
                    originalAlbums={originalAlbums.current} />
                <div className="mb-3">
                    <GiftNotification giftPreload={gifts} />
                </div>
                <div className="mb-3">
                    <ShareNotification albumsList={albums} />
                </div>
                <AlbumListUI albums={albums} handleAction={handleOpen} gifts={gifts} isAdmin={activeAdmin} handleDeleteAdmin={handleDeleteAdmin} />
                {/* <CardSimple
                    otherClass={"bg-primary shadow mt-4"}
                    children={<><div className="d-flex justify-content-between">
                        <p className="fw-bold">
                            Memoria premium usata:
                        </p>
                        <p>
                            0Gb / 10Gb
                        </p>
                    </div>
                        <ProgressBarMemory percentage={0} />
                    </>} >
                </CardSimple> */}
                {albums && albums.length === 0 && <div className='text-center w-100 h-75 d-flex flex-column justify-content-center align-items-center p-4'>
                    <FontAwesomeIcon icon="fa-regular fa-face-sad-cry" className='mb-1' size='3x' />
                    <div className='mb-1 fs-4 fw-bold'>Non hai ancora nessun album</div>
                    <div className='mb-3 fs-6'>Crea una smartphoto della tua ultima vacanza o regalala ad un amico per festeggiare un evento speciale trascorso insieme!</div>
                    <Button otherClass="btn-primary p-1" onClick={goToShop}>
                        <span className="text-tertiary  fs-5">
                            <FontAwesomeIcon icon="fa-regular fa-cart-plus" className='me-2' />
                            Aggiungi un prodotto
                        </span>
                    </Button>
                </div>}
            </div>
        </Page >
    );
}
import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper/core';
import "swiper/css";
import { PlaceholdersLoaderGif } from '..'
import { ImgWithPlaceholder } from '..';

export function CarouselDetails({ images }) {

    return (
        <Swiper
            centeredSlides={true}
            autoplay={{
                delay: 2500,
                disableOnInteraction: true,
            }}
            pagination={{
                clickable: true,
            }}
            navigation={true}
            modules={[Autoplay, Pagination, Navigation]}
            className="mySwiper"
            loop={true}
        >

            {images.map((image, index) => (
                <SwiperSlide key={index}>
                    <ImgWithPlaceholder src={image}
                        className="d-block w-100 pe-none" alt={image} unselectable="on" placeholder={PlaceholdersLoaderGif.loader_1200_1200} />
                </SwiperSlide>
            ))}
        </Swiper>
    );
}
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { BottomNavigation, ListCart, Page } from '../../components';
import { calculatePriceInCart, estimateCartDeliveryDate, goToPhotobook } from '../../libs';
import { cartActions, productDataActions } from '../../store/actions';
import { Button, CardCart } from '../../ui';
import { orderService } from '../../store/services';

const _findPropertiesLabel = (choosenProps, properties) => {
    let returnValue = '';
    choosenProps.forEach((propValueId, index) => {
        const currentProp = properties.find(prop => {
            return prop.propertyValues.find(propValue => propValue.id === propValueId);
        });
        if (!currentProp) return '';
        if (currentProp.editInEditor) {
            const currentChoosen = currentProp.propertyValues.find(propValue => propValue.id === propValueId);
            if (index < choosenProps.length - 1)
                returnValue += currentChoosen.value + ', ';
            else
                returnValue += currentChoosen.value;
        }
    })
    return returnValue.replace('Con ', '');
}

export function Cart() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { products } = useSelector(state => state.cartData);
    const { subCategoryDict, productionTimeData, couriers, productsNotPresentAnymore, propertyNotPresentAnymore } = useSelector(state => state.mainData);
    const [totalPrice, setTotalPrice] = React.useState(0);
    const [removeIndexId, setRemoveIndexId] = React.useState(null);
    const [orders, setOrders] = React.useState([]);

    const dateFormatted = React.useMemo(() => {
        if (products && subCategoryDict && productionTimeData && couriers && products.length > 0) {
            const date = estimateCartDeliveryDate(products, subCategoryDict, productionTimeData, couriers)
            // format date is Tue Dec 05 2023 15:31:47 GMT+0100 (Central European Standard Time)
            // cast to format 'Martedì 5 Dicembre'
            if (date)
                return date.toLocaleDateString('it-IT', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });
        }
        return null;
    }, [products, subCategoryDict, productionTimeData, couriers]);

    React.useEffect(() => {
        dispatch(cartActions.cleanDiscount());
        const isLogged = localStorage.getItem('token');
        if (isLogged)
            orderService.getList({ status: 100 }).then((result) => {
                setOrders(result);
            });
    }, []);

    const handleUpdate = useCallback((indexProduct) => {
        if (products[indexProduct].processingType === 6) {
            // special case for photobook
            const category = subCategoryDict[products[indexProduct].subCategoryId];
            const subCategoryId = products[indexProduct].subCategoryId;
            const categoryId = products[indexProduct].categoryId;
            goToPhotobook({
                category, categoryId, subCategoryId, navigate,
                fingerPrint: products[indexProduct].fingerPrint,
                id: products[indexProduct].id
            })
        } else {
            const imagesList = products[indexProduct].imagesList;
            dispatch(productDataActions.restoreFromCart(imagesList));
            navigate(`/warn-exit-product${products[indexProduct].productUrl}&id=${products[indexProduct].id}`);
        }
    }, [dispatch, navigate, products, subCategoryDict]);

    const handleAddCount = useCallback((indexProduct) => {
        dispatch(cartActions.addCount(indexProduct));
    }, [dispatch]);

    const handleRemoveCount = useCallback((indexProduct) => {
        dispatch(cartActions.removeCount(indexProduct));
    }, [dispatch]);

    const [removingProduct, setRemovingProduct] = React.useState(-1);
    const renderedCartList = useMemo(() => {
        let totalPrice = 0;
        if (products && products.length > 0 && subCategoryDict) {
            const list = products.map((item, index) => {
                const productExist = (!productsNotPresentAnymore || !productsNotPresentAnymore.includes(item.productId)) && subCategoryDict[item.subCategoryId]?.subProductDict[item.productId];
                const propertyExist = !propertyNotPresentAnymore || !propertyNotPresentAnymore.some((property) => item.properties?.includes(property.value));
                let priceItem = null;
                if (productExist) {
                    priceItem = calculatePriceInCart({ product: item, subCategoryDict, products });
                    totalPrice += priceItem;
                }
                let propertiesText = null;
                if (item.properties) {
                    // split and cast to int
                    const properties = item.properties.split(";").map((item) => parseInt(item));
                    propertiesText = _findPropertiesLabel(properties, subCategoryDict[item.subCategoryId].subProductDict[item.productId].properties);
                }
                let editorImage = null;
                if (item.imagesList && item.imagesList.length > 0) {
                    const validIndexImage = item.imagesList.findIndex((image) => image.originalImage);
                    const template = item.template.length > 1 ? item.template[validIndexImage] : item.template[0];
                    const image = item.imagesList[validIndexImage];
                    editorImage = {
                        image: image,
                        template: template,
                        processingType: item.processingType,
                        properties: item.properties,
                    }
                }

                return (
                    <div className={`col-12 col-md-6 col-xl-4 mb-4 transition-base`}
                    key={item.id}
                        style={removingProduct === item.id ? { marginLeft: '300%' } : {}}>
                        <CardCart
                            image={item.image}
                            imageCartProp={item.imageCartProp}
                            title={item.title}
                            price={(priceItem ? priceItem.toFixed(2).replace(".", ",") : "-") + " €"}
                            text={item.text}
                            count={item.processingType !== 1 ? item.countInCart : item.totalImages}
                            handleAddCount={item.processingType !== 1 ? () => handleAddCount(index) : null}
                            handleRemoveCount={item.processingType !== 1 ? () => handleRemoveCount(index) : null}
                            handleUpdate={item.processingType !== 5 ? () => handleUpdate(index) : null}
                            setRemoveIndexId={() => setRemoveIndexId({ index, id: item.id })}
                            productNotExist={!productExist}
                            propertyNotExist={!propertyExist}
                            propertiesText={propertiesText}
                            editorImage={editorImage}
                        />
                    </div>
                )
            });
            setTotalPrice(totalPrice);
            return list;
        }
        return null;
    }, [products, subCategoryDict, productsNotPresentAnymore, propertyNotPresentAnymore, removingProduct, handleAddCount, handleRemoveCount, handleUpdate]);

    const goToShop = useCallback(() => {
        navigate('/shop');
    }, [navigate]);

    const handleCompleteOrder = useCallback(() => {
        if (!localStorage.getItem('token')) {
            navigate('/login');
            return;
        }
        navigate('/complete-order');
    }, [navigate]);

    const renderList = useMemo(() => {
        if (products && products.length > 0) {
            return <ListCart renderedCartList={renderedCartList} removeIndexId={removeIndexId} setRemovingProduct={setRemovingProduct} />
        }
        return <div className='text-center w-100 h-100 d-flex flex-column justify-content-center align-items-center p-4'>
            <FontAwesomeIcon icon="fa-regular fa-cart-flatbed-empty" className='mb-1' size='3x' />
            <div className='mb-1 fs-4 fw-bold'>Il tuo carrello è vuoto</div>
            <div className='mb-3 fs-6'>Crea una smartphoto della tua ultima vacanza o regalala ad un amico per festeggiare un evento speciale trascorso insieme!</div>
            <Button otherClass="btn-primary p-1" onClick={goToShop}>
                <span className="text-tertiary  fs-5">
                    <FontAwesomeIcon icon="fa-regular fa-cart-plus" className='me-2' />
                    Aggiungi un prodotto
                </span>
            </Button>
        </div>
    }, [products, goToShop, renderedCartList, removeIndexId]);

    const daysLeft = useMemo(() => {
        // products have expirationDate parameter
        // first found the oldest expiration date
        let oldestDate = null;
        products.forEach((item) => {
            if (item.expirationDate) {
                const date = new Date(item.expirationDate);
                if (!oldestDate || date < oldestDate)
                    oldestDate = date;
            }
        });
        if (oldestDate) {
            const now = new Date();
            const diffTime = oldestDate - now;
            const diffDays = diffTime / (1000 * 60 * 60 * 24);
            if (diffDays <= 0) {
                dispatch(cartActions.removeAll());
                return null;
            }
            return Math.ceil(diffDays);
        }
        return null;
    }, [dispatch, products]);

    return (
        <Page
            customTopSection={<React.Fragment>
                <h2 className={`text-center fw-bold mb-0${!daysLeft && orders?.length === 0 ? 'p-2' : ''}`}>Carrello</h2>
                {orders.length > 0 && <div className='text-center'>Hai degli
                    <span className={`text-primary ms-1 me-1 text-decoration-underline`} onClick={() => navigate('/orders')}>ordini</span>
                    in attesa di pagamento</div>}
                {daysLeft && <div className={`text-center text-secondary`}>Il tuo carrello scadrà tra {daysLeft} giorni</div>}
                <div className='horizontal-line' />
            </React.Fragment>
            }
            customBottomSection={
                products?.length > 0 && <React.Fragment>
                    <div className='p-2 border border-bottom  text-end'>
                        {dateFormatted && <div className='d-flex justify-content-between align-items-center mb-1 fw-bold'>
                            <div className='d-flex align-items-center'>
                                <div className=''>Consegna stimata:</div>
                            </div>
                            <div className='text-primary'>{dateFormatted}</div>
                        </div>}
                        <div className='d-flex justify-content-between align-items-center mb-1 fw-bold'>
                            <div>Totale</div>
                            <div className='text-primary'>{totalPrice.toFixed(2).replace(".", ",")} €</div>
                        </div>
                        <Button otherClass={"fs-6 btn-primary p-1 w-auto ps-2 pe-2"} onClick={handleCompleteOrder}>
                            <FontAwesomeIcon icon="fa-regular fa-bag-shopping" className='me-2' />
                            Concludi Ordine
                        </Button>
                    </div>
                    <BottomNavigation />
                </React.Fragment>
            }>
                {renderList}
        </Page>
    );
}

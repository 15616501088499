import React from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Page } from '../../components';
import { CardSmall, LabelIconText, CarouselSmall } from '../../ui';
import { productDataActions } from '../../store/actions';

export function Shop() {
    const { categoryDict, categoryIDs, subCategoryDict } = useSelector(state => state.mainData);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [search, setSearch] = React.useState('');
    const [idAdmitted, setIdAdmitted] = React.useState([]);
    const delaySearch = React.useRef(null);
    const askedToInstall = localStorage.getItem('askedToInstall') === 'true';

    const handleCardClick = (categoryId, subCategoryId) => {
        navigate(`/details?categoryId=${categoryId}&subCategoryId=${subCategoryId}`);
    }

    React.useEffect(() => {
        if (delaySearch.current)
            clearTimeout(delaySearch.current);
        delaySearch.current = setTimeout(() => {
            if (search.length > 0) {
                const newIdAdmitted = [];
                Object.keys(subCategoryDict).forEach((subCategoryId) => {
                    if (subCategoryDict[subCategoryId].name.toLowerCase().includes(search.toLowerCase())) {
                        newIdAdmitted.push(parseInt(subCategoryId));
                    }
                });
                if (newIdAdmitted.length === 0)
                    newIdAdmitted.push(-1);
                setIdAdmitted(newIdAdmitted);
            }
            else
                setIdAdmitted([]);
        }, 500);
    }, [search, subCategoryDict]);

    React.useEffect(() => {
        dispatch(productDataActions.unlockClean());
    }, [dispatch]);

    const checkIdsAdmitted = (subCategoryIds) => {
        if (idAdmitted.length > 0)
            return subCategoryIds.some((subCategoryId) => idAdmitted.includes(subCategoryId));
        else
            return true;
    }

    const checkSearchInTitle = (title) => {
        if (search.length > 0)
            return title.toLowerCase().includes(search.toLowerCase());
        else
            return true;
    }

    const noElementFound = idAdmitted.length === 1 && idAdmitted[0] === -1;

    return (
        <Page logoOnTop={true}>
            <div className='p-3 pt-1'>
                <div className='w-100 text-center fw-bold mt-3 fs-1'>
                    Prodotti
                </div>
                {noElementFound && <h4 className='w-100 text-center mt-3 text-danger'>
                    Nessun prodotto trovato, prova con una diversa ricerca
                </h4>}
                {!noElementFound && categoryIDs?.map((categoryId, index) => {
                    const searchedProduct = idAdmitted.length === 0 ? categoryDict[categoryId].subCategoryIDs
                        : categoryDict[categoryId].subCategoryIDs?.filter((subCategoryId) => idAdmitted.includes(subCategoryId) || checkSearchInTitle(categoryDict[categoryId].name));
                    const notCarouselElement = searchedProduct.filter((subCategoryId) => (subCategoryDict[subCategoryId].layoutType !== 2));
                    const carouselElement = searchedProduct.filter((subCategoryId) => subCategoryDict[subCategoryId].layoutType === 2);
                    const allowed = categoryId < 1000000 || localStorage.getItem('isAdmin') === 'true' || localStorage.getItem('isProfessional') === 'true'; // products for professionals
                    return (checkIdsAdmitted(categoryDict[categoryId].subCategoryIDs) || checkSearchInTitle(categoryDict[categoryId].name)) && allowed ?
                        (<div className='row mt-3' key={index}>
                            <LabelIconText
                                text={categoryDict[categoryId].name}
                                otherClass="" />
                            {notCarouselElement.length > 0 && notCarouselElement.map((subCategoryId, index) => {
                                return <div onClick={() => handleCardClick(categoryId, subCategoryId)} key={index}
                                    className={`${subCategoryDict[subCategoryId].layoutType === 1 ? "col-12 col-sm-6 col-md-4" : "col-6 col-md-4"}`}>
                                    <CardSmall
                                        key={index}
                                        title={subCategoryDict[subCategoryId].name}
                                        image={subCategoryDict[subCategoryId].image}
                                        otherClass="cursor-pointer mb-3 mt-3"
                                    />
                                </div>
                            }
                            )}
                            {carouselElement.length > 0 &&
                                <CarouselSmall
                                    elements={carouselElement.map((subCategoryId, index) => {
                                        return <div onClick={() => handleCardClick(categoryId, subCategoryId)} key={index}
                                            className={`mb-4 mt-n1`}>
                                            <CardSmall
                                                key={index}
                                                title={subCategoryDict[subCategoryId].name}
                                                image={subCategoryDict[subCategoryId].image}
                                                otherClass="cursor-pointer mt-3 mb-3"
                                            />
                                        </div>
                                    })}
                                />}
                        </div>)
                        : null
                }
                )}
            </div>
            <div className='w-100 text-center fw-bold'>
                ...
            </div>
            <div className='w-100 text-center fw-bold mb-5 ps-2 pe-2'>
                Sei un professionista interessato a collaborare con noi?
                Contattaci alla mail: <a href="mailto:info@kangury.it"> info@kangury.it</a>
            </div>
            <div className='w-100 text-center fw-bold mb-5 ps-2 pe-2'>
                Le funzionalità esistenti sono in continuo miglioramento e nuove altre arriveranno, ti ringraziamo per il tuo supporto
            </div>
        </Page >
    );
}

import React from 'react';
import { Page } from '../../../components';
import { Button } from '../../../ui';
import { useNavigate } from 'react-router-dom';

export function AdminPanel() {
    const navigate = useNavigate();

    return (
        <Page visibleBottomNavigation={false}
            title={"Pannello Amministratore"}>

            <div className='p-2'>
                <Button otherClass={"btn-primary mb-2"} onClick={() => navigate('/admin-nfc')}>
                    Vai al lettore NFC
                </Button>
                <Button otherClass={"btn-primary mb-2"} onClick={() => navigate('/admin-discounts')}>
                    Vai ai codici sconto
                </Button>
                <Button otherClass={"btn-primary mb-2"} onClick={() => navigate('/admin-create-album')}>
                    Vai al crea album
                </Button>
                <Button otherClass={"btn-primary mb-2"} onClick={() => navigate('/admin-unlock-order')}>
                    Sblocca un ordine
                </Button>
            </div>
        </Page>
    );
}